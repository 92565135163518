import { clauseEndpoints, postRequest } from "@lb/frontend";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleSubmit = (values, { setSubmitting }) => {
      postRequest({
        url: clauseEndpoints.create(),
        data: values,
        baseUrl: "clm",
      })
        .then(() => {
          queryClient.invalidateQueries(["clauses"]);
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    return <Component {...props} {...{ handleSubmit }} />;
  };

export default engine;
