import { LbActionFooter, LbButton, LbPatternField, LbSelect, LbTitle, getAvatarFromName, orgEndpoints, postRequest } from "@lb/frontend";
import {
  ORG_STRUCTURE,
  TDS_SECTION_OPTIONS,
  MSME_PATTERN,
  PAN_PATTERN,
  TAN_PATTERN,
  UDYAM_PATTERN,
  CIN_PATTERN,
  GSTIN_PATTERN,
  LLPIN_PATTERN,
} from "@lb/utils";
import { Grid, InputAdornment, MenuItem, Select, Stack, Toolbar, alpha, useTheme } from "@mui/material";
import { Formik } from "formik";
import { keys, pick } from "lodash";
import React, { useState } from "react";

function StatutoryDetails({ submitHandler, data, organizationId, refetch, nextStep, previousStep }) {
  const theme = useTheme();
  const [type, setType] = useState("msme");
  const [loading, setLoading] = useState(false);

  return (
    <Stack spacing={4} alignItems="flex-start">
      <Stack spacing={1} alignItems={"end"}>
        <LbTitle>Trademark:</LbTitle>
        <img
          src={data?.trademark?.url || getAvatarFromName(data?.name)}
          alt={data?.trademark}
          style={{
            padding: 5,
            height: "100px",
            objectFit: "contain",
            border: "4px inset #ccc",
          }}
        />

        <LbButton sx={{ width: "fit-content" }} loading={loading}>
          <input
            type="file"
            accept="image/*"
            onChange={(event) => {
              setLoading(true);
              var formdata = new FormData();
              formdata.append("docType", "trademark");
              formdata.append("file", event.target.files[0]);

              return postRequest({
                url: orgEndpoints.uploadDoc(organizationId),
                data: formdata,
                baseUrl: "master",
              })
                .then(() => {
                  refetch();
                })
                .finally(() => setLoading(false));
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />
          Change
        </LbButton>
      </Stack>

      <Formik
        onSubmit={submitHandler}
        initialValues={{
          tdsSection: data?.tdsSection,
          governmentId: {
            ...ORG_STRUCTURE.STATUTORY_DETAILS.governmentId,
            ...pick(data?.governmentId, keys(ORG_STRUCTURE.STATUTORY_DETAILS.governmentId)),
          },
        }}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values, dirty, touched }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={4} columnSpacing={3} pt={2.5}>
                <Grid item md={4}>
                  <LbSelect name="tdsSection" options={TDS_SECTION_OPTIONS.labelValue} placeholder="Select TDS section" label="TDS Section" />
                </Grid>

                <Grid item md={4}>
                  <LbPatternField name="governmentId.gstin" label="GSTIN Number" pattern={GSTIN_PATTERN} placeholder="Enter GSTIN Number" />
                </Grid>
                <Grid item md={4}>
                  <LbPatternField name="governmentId.pan" label="PAN Number" pattern={PAN_PATTERN} placeholder="Enter Pan Number" />
                </Grid>
                <Grid item md={4}>
                  <LbPatternField name="governmentId.tan" label="TAN Number" pattern={TAN_PATTERN} placeholder="Enter TAN Number" />
                </Grid>

                <Grid item md={4}>
                  <LbPatternField
                    name={`governmentId.${type === "msme" ? "msme" : "udyam"}`}
                    label="MSME/ UDYAM  Number"
                    pattern={type === "msme" ? MSME_PATTERN : UDYAM_PATTERN}
                    placeholder={`Enter ${type === "msme" ? "MSME" : "UDYAM"} Number`}
                    fastField={false}
                    inputProps={{
                      sx: { "& .MuiOutlinedInput-root": { pl: 1 } },
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              size="small"
                              value={type}
                              onChange={(e) => {
                                setType(e.target.value);
                              }}
                              sx={{
                                "& *": {
                                  border: "none",
                                  fontSize: "14px!important",
                                },
                                "& .MuiSelect-select": {
                                  pr: "25px!important",
                                  py: "5px!important",
                                  pl: 0,
                                },
                                "& svg": {
                                  position: "absolute",
                                },
                                backgroundColor: alpha(theme.palette.primary.light, 0.07),
                                borderRadius: "3px!important",
                              }}
                            >
                              <MenuItem value={"msme"}>MSME</MenuItem>
                              <MenuItem value={"udyam"}>UDYAM</MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>

                {["COMP", "LLP"].includes(data?.venOrgType) && (
                  <Grid item md={4}>
                    {
                      {
                        COMP: <LbPatternField name="governmentId.cin" label="CIN" pattern={CIN_PATTERN} placeholder="Enter Corporate Identification Number" />,
                        LLP: (
                          <LbPatternField
                            name="governmentId.llpin"
                            label="LLPIN"
                            pattern={LLPIN_PATTERN}
                            placeholder="Limited Liability Partnership Identification No."
                          />
                        ),
                      }[data?.venOrgType]
                    }
                  </Grid>
                )}
              </Grid>
              <Toolbar />

              <LbActionFooter>
                <LbButton type="button" disabled={isSubmitting} color="warning" onClick={previousStep}>
                  Back
                </LbButton>

                <LbButton type={dirty ? "submit" : "button"} loading={isSubmitting} onClick={dirty ? handleSubmit : nextStep}>
                  {dirty ? "Update" : "Next"}
                </LbButton>
              </LbActionFooter>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
}

export default StatutoryDetails;
