import { ORG_STRUCTURE } from "@lb/utils";
import { useFormik } from "formik";
import React from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { submitHandler, data } = props;

    const formik = useFormik({
      initialValues: {
        notifications: {
          ...ORG_STRUCTURE.NOTIFICATIONS.notifications,
          ...data?.notifications,
        },
      },
      onSubmit: submitHandler,
    });

    return <Component {...props} {...{ formik }} />;
  };

export default engine;
