import {
  getRequest,
  orgEndpoints,
  putRequest,
  useReactQuery,
} from "@lb/frontend";
import { organizationsClientUrls } from "@lb/utils";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { organizationId } = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
      navigate(organizationsClientUrls.base);
    };

    const { data: { data: { nomenclatures } = {} } = {}, isLoading } =
      useReactQuery({
        queryKeys: ["organization-nomenclatures", organizationId],
        apiCall: () =>
          getRequest({
            url: orgEndpoints.getById(organizationId).nomenclatures,
            baseUrl: "master",
          }),
        options: {
          enabled: Boolean(organizationId),
        },
      });

    const onSubmit = (values, { setSubmitting }) => {
      putRequest({
        url: orgEndpoints.update(organizationId),
        data: { nomenclatures: values },
        baseUrl: "master",
      })
        .then((res) => {
          handleClose();
        })
        .finally(() => setSubmitting(false));
    };

    return (
      <Component
        {...props}
        {...{ organizationId, nomenclatures, isLoading, handleClose, onSubmit }}
      />
    );
  };

export default engine;
