import {
  LbActionFooter,
  LbButton,
  LbSearch,
  LbSwitch,
  LbTitle,
  usersEndpoints,
} from "@lb/frontend";
import { AdminPanelSettings } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";
import { PiHandshake } from "react-icons/pi";
import engine from "./engine";

function Basic({ data, formik }) {
  return (
    <FormikProvider value={formik}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <AdminPanelSettings />
            Admin Settings
          </LbTitle>
          <LbSearch
            label="Admin"
            name="modules.drm.admin"
            optionLabel="email"
            placeholder="Search users"
            queryParamName="search"
            requestUrl={usersEndpoints.search(data._id)}
            idKey="_id"
            baseUrl={"iam"}
          />

          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ my: 3 }}
          >
            <PiHandshake fontSize={22} />
            Organization Registry Control
          </LbTitle>

          <Stack spacing={3}>
            <LbSwitch
              name="modules.drm.documents.download"
              label="Organization can download documents"
            />

            <LbSwitch
              name="modules.drm.documents.upload"
              label="Organization can upload documents"
            />

            <LbSwitch
              name="modules.drm.documents.update"
              label="Organization can update documents"
            />

            <LbSwitch
              name="modules.drm.documents.manageTags"
              label="Organization can manage document tags"
            />

            <LbSwitch
              name="modules.drm.documents.delete"
              label="Organization can delete documents"
            />
          </Stack>

          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ my: 3 }}
          >
            <PiHandshake fontSize={22} />
            Organization Document Tags Control
          </LbTitle>

          <Stack spacing={3}>
            <LbSwitch
              name="modules.drm.tags.create"
              label="Organization can create tags"
            />

            <LbSwitch
              name="modules.drm.tags.update"
              label="Organization can update tags"
            />

            <LbSwitch
              name="modules.drm.tags.delete"
              label="Organization can delete documents"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={6}></Grid>
      </Grid>

      <LbActionFooter sx={{ zIndex: 5 }}>
        <LbButton
          color="error"
          type="button"
          onClick={formik.resetForm}
          disabled={formik.isSubmitting}
        >
          Reset
        </LbButton>
        <LbButton
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
        >
          Update
        </LbButton>
      </LbActionFooter>
    </FormikProvider>
  );
}

export default engine(Basic);
