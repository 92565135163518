import {
  contractsTemplatesEndpoints,
  getRequest,
  postRequest,
  putRequest,
  useHash,
  useReactQuery
} from "@lb/frontend";
import { masterClientUrls } from "@lb/utils";
import { ArticleOutlined, Notes } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { findIndex, includes } from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const steps = [
  { label: "Metadata", icon: Notes, value: "metadata" },
  {
    label: "Variables",
    icon: Notes,
    value: "variables",
  },
  { label: "Transcript", icon: ArticleOutlined, value: "transcript" },
  { label: "Preview", icon: ArticleOutlined, value: "preview" },
];

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();
    const { templateId, orgId } = useParams();
    const queryClient = useQueryClient();
    const { hash, setHash } = useHash();

    useEffect(() => {
      if (!includes(["metadata", "variables"], hash)) setHash("metadata");
    }, [hash]);

    const nextStep = () => {
      const currentIndex = findIndex(steps, { value: hash });
      setHash(steps[currentIndex + 1]?.value);
    };

    const previousStep = () => {
      const currentIndex = findIndex(steps, { value: hash });
      setHash(steps[currentIndex - 1]?.value);
    };

    const handleClose = () => {
      navigate(masterClientUrls.clm.templates.base);
    };

    // const { data: { data: orgData = null } = {} } = useReactQuery({
    //   queryKeys: ["organization", orgId],
    //   apiCall: () =>
    //     getRequest({
    //       url: orgEndpoints.getById(orgId).base,
    //       baseUrl: "master",
    //     }),
    //   options: {
    //     enabled: Boolean(orgId) && orgId !== "public",
    //     cacheTime: 0,
    //   },
    // });

    // const formik = useFormik({
    //   initialValues: { organization: orgData },
    //   enableReinitialize: true,
    // });

    const { data: { data: data = {} } = {}, isLoading } = useReactQuery({
      queryKeys: ["templates", templateId, orgId],
      apiCall: () =>
        getRequest({
          url: contractsTemplatesEndpoints.getById(templateId, orgId),
          baseUrl: "clm",
        }),
      options: {
        enabled: Boolean(templateId),
        cacheTime: 0,
      },
    });

    const handleSubmit = (values, { setSubmitting }) => {
      (templateId ? putRequest : postRequest)({
        url: contractsTemplatesEndpoints.update(templateId, orgId),
        data: values,
        baseUrl: "clm",
      })
        .then(() => {
          queryClient.invalidateQueries(["templates"]);
          handleClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    return (
      <Component
        {...props}
        {...{
          handleSubmit,
          handleClose,
          templateId,
          orgId,
          isLoading,
          data,
          steps,
          previousStep,
          nextStep,
          hash,
          setHash,
        }}
      />
    );
  };

export default engine;
