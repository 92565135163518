import { LbLoading, LbStepper, LbTitle } from "@lb/frontend";
import { organizationsClientUrls } from "@lb/utils";
import { Box, Stack } from "@mui/material";
import React, { Fragment, createElement } from "react";
import Documents from "./Documents";
import MetaData from "./MetaData";
import StatutoryDetails from "./StatutoryDetails";
import engine from "./engine";
import Storage from "./Storage";
import Colors from "./Colors";
import Notifications from "./Notifications";

function OrganizationForm({
  organizationId,
  submitHandler,
  steps,
  hash,
  setHash,
  data,
  refetch,
  isLoading,
  previousStep,
  nextStep,
}) {
  return (
    <Fragment>
      <LbTitle
        link={organizationsClientUrls.base}
        loading={isLoading}
        stackProps={{ mb: 2 }}
        tooltip={"Back to organizations list"}
      >
        {data?.name}
      </LbTitle>

      <Stack direction="row" columnGap={5} alignItems="self-start">
        <LbStepper {...{ activeStep: hash, setActiveStep: setHash, steps }} />
        <Box width="100%">
          {isLoading ? (
            <LbLoading />
          ) : (
            createElement(
              {
                metadata: MetaData,
                "statutory-details": StatutoryDetails,
                documents: Documents,
                storage: Storage,
                notifications: Notifications,
                colors: Colors,
              }[hash] || MetaData,
              {
                submitHandler,
                data,
                organizationId,
                refetch,
                previousStep,
                nextStep,
              }
            )
          )}
        </Box>
      </Stack>
    </Fragment>
  );
}

export default engine(OrganizationForm);
