import {
  LbActionFooter,
  LbAutocomplete,
  LbButton,
  LbLoading,
  LbStepper,
  LbTags,
  LbTitle,
} from "@lb/frontend";
import {
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES,
  INDUSTRY_OPTIONS,
  ORG_TYPES,
  masterClientUrls,
} from "@lb/utils";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Form, FormikProvider } from "formik";
import React, { Children, Fragment, createElement } from "react";
import { Link } from "react-router-dom";
import engine from "./engine";
import { join, map } from "lodash";
import Metadata from "./Metadata";
import Variables from "./Variables";

function Edit({
  formik,
  isLoading,
  steps,
  nextStep,
  previousStep,
  hash,
  setHash,
}) {
  return (
    <Fragment>
      <LbTitle
        link={masterClientUrls.clm.clauses.base}
        tooltip="Back to clauses list"
        stackProps={{ mb: 3 }}
      >
        {join(formik.values.names, " | ")}
      </LbTitle>

      <Stack direction="row" columnGap={5} alignItems="self-start">
        <Stack width="100%" spacing={2} flex={1}>
          <LbStepper {...{ activeStep: hash, setActiveStep: setHash, steps }} />
        </Stack>
        <Box width="85%" pb={10}>
          {createElement(
            {
              metadata: Metadata,
              variables: Variables,
            }[hash] || Metadata,
            {
              formik,
              isLoading,
              previousStep,
              nextStep,
            }
          )}
        </Box>
      </Stack>
    </Fragment>
  );
}

export default engine(Edit);
