import { lbUid } from "@lb/frontend";
import { useFormik } from "formik";
import React from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const formik = useFormik({ initialValues: { content: [] } });

    function handleDragEnd(result) {
      if (!result.destination) {
        return;
      }
      const newItems = Array.from(formik.values.content);
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      formik.setFieldValue("content", newItems);
    }

    const addNew = () => {
      formik.setFieldValue("content", [
        ...formik.values.content,
        {
          label: "Untitled",
          placeholder: "Untitled",
          name: lbUid(),
          type: "text",
          required: true,
          render: true,
        },
      ]);
    };

    return <Component {...props} {...{ formik, addNew, handleDragEnd }} />;
  };

export default engine;
