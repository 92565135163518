import { useHash } from "@lb/frontend";
import { useFormik } from "formik";
import React from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { hash: tab, setHash: setTab } = useHash();
    const { data, updateModuleConfig } = props;

    const formik = useFormik({
      initialValues: { modules: { clm: data?.modules?.clm } },
      onSubmit: updateModuleConfig,
      enableReinitialize: true,
    });

    return <Component {...props} {...{ setTab, tab, formik }} />;
  };

export default engine;
