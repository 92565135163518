import {
  documentsEndpoints,
  getRequest,
  postRequest,
  putRequest,
  useReactQuery,
} from "@lb/frontend";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { controller } = props;
    const { organizationId } = useParams();
    const queryClient = useQueryClient();

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: [`tags-${controller._id}`],
      apiCall: () =>
        getRequest({
          url: documentsEndpoints.tags.getById(controller._id, organizationId),
          baseUrl: "drm",
        }),
      options: {
        enabled: Boolean(controller._id),
        cacheTime: 0,
      },
    });

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: data?.name || "",
        options: data?.options || [],
      },
      validationSchema: Yup.object({
        name: Yup.string().required(),
        options: Yup.array().min(1, "at least 1").required("required"),
      }),
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        try {
          await (controller._id ? putRequest : postRequest)({
            url: controller._id
              ? documentsEndpoints.tags.update(
                  controller._id,
                  organizationId
                )
              : documentsEndpoints.tags.create(organizationId),
            data: values,
            baseUrl: "drm",
          });

          queryClient.invalidateQueries({
            queryKey: "tags",
            type: "all",
          });
          if (controller._id) await refetch();
          else resetForm();
        } catch (error) {
        } finally {
          setSubmitting(false);
        }
      },
    });

    const handleClose = () => {
      formik.resetForm();
      controller.set({ open: false, _id: null });
    };

    return (
      <Component
        {...props}
        {...{ handleClose, organizationId, formik, data, isLoading }}
      />
    );
  };

export default engine;
