import { useFormik } from "formik";
import React from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { data, updateModuleConfig } = props;

    const formik = useFormik({
      initialValues: { modules: { drm: data?.modules?.drm } },
      onSubmit: updateModuleConfig,
      enableReinitialize: true,
    });

    return <Component {...props} {...{ formik }} />;
  };

export default engine;
