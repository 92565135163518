import React, { Children, Fragment } from "react";
import engine from "./engine";
import { LbButton, LbDataTable, LbMoreMenu, LbStatusChip } from "@lb/frontend";
import { map } from "lodash";
import { Link, generatePath } from "react-router-dom";
import {
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES,
  ORG_TYPES,
  masterClientUrls,
} from "@lb/utils";
import { FiEdit3 } from "react-icons/fi";
import { Visibility } from "@mui/icons-material";
import { Stack } from "@mui/material";

function Clauses({
  data,
  isLoading,
  setSearchVal,
  pagination,
  setPagination,
  organization,
}) {
  return (
    <Fragment>
      <LbDataTable
        title="Clauses"
        {...{ pagination, isLoading, setSearchVal, setPagination }}
        height={"100%"}
        data={map(
          data,
          ({
            names,
            businessTypes,
            businessCategories,
            orgTypes,
            _id,
          } = {}) => [names, businessTypes, businessCategories, orgTypes, _id]
        )}
        columns={[
          {
            name: "names",
            label: "Name",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack
                    direction={"row"}
                    rowGap={1}
                    columnGap={1}
                    flexWrap={"wrap"}
                  >
                    {Children.toArray(
                      map(value, (item) => (
                        <LbStatusChip status={value}></LbStatusChip>
                      ))
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "businessTypes",
            label: "Business Type",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack
                    direction={"row"}
                    rowGap={1}
                    columnGap={1}
                    flexWrap={"wrap"}
                  >
                    {Children.toArray(
                      map(value, (item) => (
                        <LbStatusChip
                          status={BUSINESS_TYPES._[item]}
                        ></LbStatusChip>
                      ))
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "businessCategories",
            label: "Business Category",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack
                    direction={"row"}
                    rowGap={1}
                    columnGap={1}
                    flexWrap={"wrap"}
                  >
                    {Children.toArray(
                      map(value, (item) => (
                        <LbStatusChip
                          status={BUSINESS_CATEGORIES._[item]}
                        ></LbStatusChip>
                      ))
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "orgTypes",
            label: "Organization Type",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack
                    direction={"row"}
                    rowGap={1}
                    columnGap={1}
                    flexWrap={"wrap"}
                  >
                    {Children.toArray(
                      map(value, (item) => (
                        <LbStatusChip
                          status={ORG_TYPES._[item]}
                        ></LbStatusChip>
                      ))
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (_id) => {
                return (
                  <LbMoreMenu
                    menus={[
                      {
                        label: "Edit",
                        icon: FiEdit3,
                        path: generatePath(
                          masterClientUrls.clm.clauses.edit.base,
                          {
                            clauseId: _id,
                            orgId: organization._id || "public",
                          }
                        ),
                      },
                      {
                        label: "View",
                        icon: Visibility,
                        path: generatePath(masterClientUrls.clm.clauses.base),
                      },
                    ]}
                  />
                );
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          setTableProps: () => {
            return {
              size: "small",
            };
          },
          customToolbar: ({ displayData }) => {
            return (
              <LbButton
                sx={{ order: -1 }}
                variant="outlined"
                component={Link}
                to={masterClientUrls.clm.clauses.add.base}
              >
                Add/ Create
              </LbButton>
            );
          },
        }}
      />
    </Fragment>
  );
}

export default engine(Clauses);
export { default as AddClause } from "./Add";
export { default as EditClause } from "./Edit";
export * from "./Groups";
export { default as ClauseGroup } from "./Groups";
