import React, { Fragment, createElement } from "react";
import engine from "./engine";
import { Box, Stack } from "@mui/material";
import { LbStepper } from "@lb/frontend";
import Metadata from "./Metadata";
import Variables from "./Variables";
import Transcript from "./Transcript";
import Preview from "./Preview";

function Update({
  hash,
  setHash,
  steps,
  previousStep,
  nextStep,
  data,
  handleSubmit,
}) {
  return (
    <Fragment>
      <Stack direction="row" columnGap={5} alignItems="self-start">
        <Stack width="100%" spacing={2} flex={1}>
          <LbStepper {...{ activeStep: hash, setActiveStep: setHash, steps }} />
        </Stack>
        <Box width="85%" pb={10}>
          {createElement(
            {
              metadata: Metadata,
              variables: Variables,
              transcript: Transcript,
              preview: Preview,
            }[hash] || Metadata,
            {
              previousStep,
              nextStep,
              data,
              handleSubmit,
            }
          )}
        </Box>
      </Stack>
    </Fragment>
  );
}

export default engine(Update);
