import { LbTabs } from "@lb/frontend";
import { Box } from "@mui/material";
import React, { Fragment, createElement } from "react";
import Basic from "./Basic";
import Documents from "./Documents";
import Tags from "./Tags";
import engine from "./engine";

function DRMConfig({ data, setTab, tab, updateModuleConfig }) {
  return (
    <Fragment>
      <Box>
        <LbTabs
          activeTab={tab}
          handleTabChange={setTab}
          tabs={[
            {
              label: "Basic",
              value: "basic",
            },
            {
              label: "Tags",
              value: "tags",
            },
            {
              label: "Documents",
              value: "documents",
            },
          ]}
        />
      </Box>

      <Box pt={2} pb={10}>
        {createElement(
          {
            basic: Basic,
            tags: Tags,
            documents: Documents,
          }[tab] || Basic,
          { data, updateModuleConfig }
        )}
      </Box>
    </Fragment>
  );
}
export default engine(DRMConfig);
