import {
  LbButton,
  LbDataTable,
  LbMoreMenu,
  LbSearch,
  orgEndpoints,
} from "@lb/frontend";
import { masterClientUrls } from "@lb/utils";
import { Visibility } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { map } from "lodash";
import React, { Fragment } from "react";
import { FiEdit3 } from "react-icons/fi";
import { Link, generatePath } from "react-router-dom";
import engine from "./engine";
import { FormikProvider } from "formik";

function Templates({
  data,
  isLoading,
  pagination,
  setSearchVal,
  setPagination,
  formik,
}) {
  return (
    <Fragment>
      <LbDataTable
        {...{
          pagination,
          isLoading,
          setSearchVal,
          setPagination,
          title: (
            <Grid
              container
              alignItems={"center"}
              spacing={3}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Typography>Templates</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormikProvider value={formik}>
                  <LbSearch
                    name="organization"
                    label="Organization"
                    optionLabel="name"
                    queryParamName="search"
                    placeholder="Search organizations"
                    requestUrl={orgEndpoints.search}
                    baseUrl="master"
                  />
                </FormikProvider>
              </Grid>
            </Grid>
          ),
          data: map(data, ({ name, description, abbreviation, _id }) => [
            name,
            description,
            abbreviation,
            _id,
          ]),
          options: {
            setTableProps: () => {
              return {
                size: "small",
              };
            },
            customToolbar: ({ displayData }) => {
              return (
                <Fragment>
                  <LbButton
                    sx={{ order: -1 }}
                    variant="outlined"
                    component={Link}
                    to={masterClientUrls.clm.templates.add.base}
                  >
                    Add/ Create
                  </LbButton>
                </Fragment>
              );
            },
          },
          columns: [
            "Name",
            "Description",
            {
              name: "abbreviation",
              label: "Abbreviation",
              options: {
                setCellProps: (value) => {
                  return {
                    align: "center",
                  };
                },
                setCellHeaderProps: (value) => {
                  return {
                    align: "center",
                  };
                },
              },
            },
            {
              name: "action",
              label: "Action",
              options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value) => (
                  <LbMoreMenu
                    menus={[
                      {
                        label: "Edit",
                        icon: FiEdit3,
                        path: generatePath(
                          masterClientUrls.clm.templates.edit.base,
                          {
                            templateId: value,
                            orgId: formik.values.organization?._id || "public",
                          }
                        ),
                      },
                      {
                        label: "View",
                        icon: Visibility,
                        // path: generatePath(
                        //   masterClientUrls.clm.clauses.groups.base
                        // ),
                      },
                    ]}
                  />
                ),
                setCellProps: (value) => {
                  return {
                    align: "center",
                  };
                },
                setCellHeaderProps: (value) => {
                  return {
                    align: "center",
                  };
                },
              },
            },
          ],
        }}
      />
    </Fragment>
  );
}

export default engine(Templates);
export { default as CreateTemplate } from "./Create";
export { default as UpdateTemplate } from "./Update";
