import {
  contractsTemplatesEndpoints,
  getRequest,
  useReactQuery,
} from "@lb/frontend";
import { useFormik } from "formik";
import React, { useState } from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const [searchVal, setSearchVal] = useState("");
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });

    const formik = useFormik({ initialValues: { organization: null } });

    const { data: { data, total } = {}, isLoading } = useReactQuery({
      queryKeys: [
        "templates",
        pagination.page,
        pagination.limit,
        // searchVal,
        formik.values.organization || "public",
      ],
      apiCall: () =>
        getRequest({
          url: contractsTemplatesEndpoints.getAll(
            formik.values.organization?._id
          ),
          params: {
            pageNo: pagination.page,
            limit: pagination.limit,
            search: searchVal,
          },
          baseUrl: "clm",
        }),
    });

    return (
      <Component
        {...props}
        {...{
          data,
          isLoading,
          setSearchVal,
          pagination: {
            ...pagination,
            total,
          },
          setPagination,
          formik,
        }}
      />
    );
  };

export default engine;
