import { LbTitle } from "@lb/frontend";
import { organizationsClientUrls } from "@lb/utils";
import { Box } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function AddInviteOrganization() {
  const { pathname } = useLocation();
  var isAdd = pathname.includes("add");

  return (
    <div>
      {/* <LbToggleButtons
        options={[
          { label: "Add", value: "add" },
          { label: "Invite", value: "invite" },
        ]}
        normal
        onChange={(e, val) => navigate(organizationsClientUrls[val].base)}
        value={isAdd ? "add" : "invite"}
        boxProps={{ width: "20%", mb: 2 }}
      /> */}
      <LbTitle
        link={organizationsClientUrls.base}
        tooltip={"Back to vendors list"}
      >
        {isAdd ? "Add new organization" : "Let organization fill their details"}
      </LbTitle>

      <Box py={3}>
        <Outlet />
      </Box>
    </div>
  );
}
