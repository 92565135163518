import { LbAppWrapper } from "@lb/frontend";
import RoutesProvider from "./RoutesProvider";

export default function App() {
  return (
      <LbAppWrapper>
        <RoutesProvider />
      </LbAppWrapper>
  );
}
