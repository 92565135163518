import {
  contractsTemplatesEndpoints,
  getRequest,
  orgEndpoints,
  postRequest,
  putRequest,
  useReactQuery,
} from "@lb/frontend";
import { masterClientUrls } from "@lb/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();
    const { templateId, orgId } = useParams();
    const queryClient = useQueryClient();

    const handleClose = () => {
      navigate(masterClientUrls.clm.templates.base);
    };

    const { data: { data: orgData = null } = {} } = useReactQuery({
      queryKeys: ["organization", orgId],
      apiCall: () =>
        getRequest({
          url: orgEndpoints.getById(orgId).base,
          baseUrl: "master",
        }),
      options: {
        enabled: Boolean(orgId) && orgId !== "public",
        cacheTime: 0,
      },
    });

    const formik = useFormik({
      initialValues: { organization: orgData },
      enableReinitialize: true,
    });

    const { data: { data: templateData = {} } = {}, isLoading } = useReactQuery(
      {
        queryKeys: ["templates", templateId, orgId],
        apiCall: () =>
          getRequest({
            url: contractsTemplatesEndpoints.getById(templateId, orgId),
            baseUrl: "clm",
          }),
        options: {
          enabled: Boolean(templateId),
          cacheTime: 0,
        },
      }
    );

    const handleSubmit = (values, { setSubmitting }) => {
      (templateId ? putRequest : postRequest)({
        url: templateId
          ? contractsTemplatesEndpoints.update(templateId, orgId)
          : contractsTemplatesEndpoints.create(
              formik.values?.organization?._id
            ),
        data: values,
        baseUrl: "clm",
      })
        .then(() => {
          queryClient.invalidateQueries(["templates"]);
          handleClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    return (
      <Component
        {...props}
        {...{
          handleSubmit,
          handleClose,
          templateId,
          orgId,
          isLoading,
          templateData,
          formik,
        }}
      />
    );
  };

export default engine;
