import { YupObjSchema, useHash } from "@lb/frontend";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { hash: tab, setHash: setTab } = useHash();
    const { updateModuleConfig, data } = props;

    const formik = useFormik({
      initialValues: {
        admin: data?.admin,
        administrativeDepartment: data?.administrativeDepartment,
        modules: { iam: data?.modules?.iam },
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        admin: YupObjSchema("_id"),
        administrativeDepartment: YupObjSchema("_id"),
        modules: Yup.object({
          iam: Yup.object({
            users: Yup.object({
              linkExpiry: Yup.object({
                invitation: Yup.object({
                  duration: Yup.string().required("Required"),
                }),
                signature: Yup.object({
                  duration: Yup.string().required("Required"),
                }),
              }),
            }),
            vendors: Yup.object({
              linkExpiry: Yup.object({
                invitation: Yup.object({
                  duration: Yup.string().required("Required"),
                }),
                signature: Yup.object({
                  duration: Yup.string().required("Required"),
                }),
              }),
            }),
          }),
        }),
      }),
      onSubmit: updateModuleConfig,
    });

    return <Component {...props} {...{ setTab, tab, formik }} />;
  };

export default engine;
