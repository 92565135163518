import React from 'react';

const engine =
  (Component) =>
  ({ ...props }) => {
    
    
    
    
    
    return <Component {...props} {...{}} />;
  };

export default engine;