import { jsonCargo } from "@lb/utils";
import React from "react";
import engine from "./engine";

function Colors({ data }) {
  return (
    <div>
      <iframe
        title="Organization Colors"
        src={`${
          process.env.REACT_APP_IAM_URL
        }/embedded/organization-colors?colors=${jsonCargo.store({
          colors: data?.colors,
        })}`}
        style={{
          width: "100%",
          height: "85vh",
          border: 0,
        }}
      />
    </div>
  );
}

export default engine(Colors);
