import { LbActionFooter, LbButton, LbSlider, LbTextField, LbToggleButtons } from "@lb/frontend";
import { STORAGE_TYPES, TRANSCRIPT_STORAGE_PROVIDERS, lbBlue } from "@lb/utils";
import { Alert, Stack, Toolbar, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import { map } from "lodash";
import React, { Fragment } from "react";
import engine from "./engine";

function Storage({ formik, nextStep, previousStep, isFormDisabled, data }) {
  return (
    <Fragment>
      {isFormDisabled && (
        <Alert severity="warning" variant="outlined" sx={{ mb: 2 }}>
          The organization needs to be in <b>maintenance</b> mode to change these configurations.
        </Alert>
      )}

      <FormikProvider value={formik}>
        <Typography color="primary.main" mb={2} sx={{ backgroundColor: lbBlue.shade15 }}>
          Data Storage
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body2" color="text.secondary">
            Type:
          </Typography>
          <LbToggleButtons disabled={isFormDisabled} boxProps={{ width: "fit-content" }} name="storage.data.type" options={STORAGE_TYPES.labelValue} />
          {formik.values.storage.data.type === STORAGE_TYPES.key.EXCLUSIVE && (
            <LbTextField
              required
              name="storage.data.credentials.connectionString"
              label="MongoDB Connection String"
              placeholder="Enter MongoDB connection string"
              boxProps={{ pt: 3 }}
              disabled={isFormDisabled}
            />
          )}
        </Stack>
        <Typography color="primary.main" mt={5} mb={2} sx={{ backgroundColor: lbBlue.shade15 }}>
          Transcripts Storage
        </Typography>

        <LbSlider
          required
          name="storage.transcripts.maxUploadSize"
          label="Max file size allowed to upload."
          sliderProps={{ min: 1, max: 100, disabled: isFormDisabled }}
          displayValue={(val) => {
            return val + " MB";
          }}
          boxProps={{ mb: 2, mt: 3 }}
        />
        <Stack direction="row" spacing={3}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Type:
            </Typography>
            <LbToggleButtons disabled={isFormDisabled} boxProps={{ width: "fit-content" }} name="storage.transcripts.type" options={STORAGE_TYPES.labelValue} />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Provider:
            </Typography>
            <LbToggleButtons
              boxProps={{ width: "fit-content" }}
              name="storage.transcripts.provider"
              options={map(TRANSCRIPT_STORAGE_PROVIDERS.labelValue, (item) => ({
                ...item,
                label: (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img alt={item.value} height="20" src={`${process.env.REACT_APP_ASSETS_URL}/logos/${item?.value}.svg`} />
                    <Typography fontWeight="bolder">{item.label}</Typography>
                  </Stack>
                ),
              }))}
              disabled={isFormDisabled}
            />
          </Stack>
        </Stack>

        {formik.values.storage.transcripts.type === STORAGE_TYPES.key.EXCLUSIVE && (
          <Fragment>
            {formik.values.storage.transcripts.provider === TRANSCRIPT_STORAGE_PROVIDERS.key.AZURE_BLOB && (
              <LbTextField
                required
                name="storage.transcripts.credentials.connectionString"
                label={`Connection String`}
                placeholder="Enter connection string"
                boxProps={{ pt: 4 }}
                disabled={isFormDisabled}
              />
            )}

            {formik.values.storage.transcripts.provider === TRANSCRIPT_STORAGE_PROVIDERS.key.AWS_S3 && (
              <Fragment>
                <LbTextField
                  required
                  name="storage.transcripts.credentials.accessKeyId"
                  label={`Access Key ID`}
                  placeholder="Enter access key ID"
                  boxProps={{ pt: 4 }}
                  disabled={isFormDisabled}
                />

                <LbTextField
                  required
                  name="storage.transcripts.credentials.secretAccessKey"
                  label={`Secret Access Key`}
                  placeholder="Enter secret access key"
                  boxProps={{ pt: 4 }}
                  disabled={isFormDisabled}
                />

                <LbTextField
                  disabled={isFormDisabled}
                  required
                  name="storage.transcripts.credentials.region"
                  label={`Region`}
                  placeholder="Enter region"
                  boxProps={{ pt: 4 }}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </FormikProvider>
      <Toolbar />
      <LbActionFooter>
        <LbButton type="button" color="error" disabled={formik?.isSubmitting} onClick={formik?.dirty ? formik?.resetForm : previousStep}>
          {formik?.dirty ? "Reset" : "Back"}
        </LbButton>
        <LbButton
          type={!formik.isValid || formik.dirty ? "submit" : "button"}
          loading={formik.isSubmitting}
          disabled={formik.isValidating}
          onClick={() => (!formik.isValid || formik.dirty ? formik.handleSubmit() : nextStep?.())}
        >
          {!formik.isValid || formik.dirty ? (formik.isValidating ? "Validating" : "Save") : "Next"}
        </LbButton>
      </LbActionFooter>
    </Fragment>
  );
}

export default engine(Storage);
