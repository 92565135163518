import { LbButton, LbChip, LbDataTable, LbMoreMenu, LbStatusChip, getBadge } from "@lb/frontend";
import { ORGANIZATION_STATUS_OPTIONS, organizationsClientUrls } from "@lb/utils";
import { CellTowerOutlined, Construction, Delete, FontDownload, Send } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { filter, keys, map, upperCase } from "lodash";
import React, { Children, Fragment } from "react";
import { FiEdit3 } from "react-icons/fi";
import { RiApps2Line } from "react-icons/ri";
import { Link, Outlet, generatePath } from "react-router-dom";
import engine from "./engine";
import filesize from "file-size";

function Organizations({ data, isLoading, pagination, setSearchVal, setPagination, deactivate, reinvite, enableMaintenanceMode, disableMaintenanceMode }) {
  const theme = useTheme();

  return (
    <Fragment>
      <LbDataTable
        {...{ isLoading, pagination, setSearchVal, setPagination }}
        height={"100%"}
        title={"Organizations"}
        data={map(data, ({ trademark, activationQuarter, status, name, count, dataSize, modules, salesRepresentative, _id }) => [
          trademark,
          activationQuarter,
          status,
          name,
          count.users,
          count.vendors,
          count.contracts,
          count.templates,
          count.documents,
          dataSize,
          modules,
          salesRepresentative,
          _id,
        ])}
        columns={[
          {
            name: "Trademark",
            label: "Trademark",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (trademark, { rowIndex }) => {
                return (
                  <img
                    src={trademark?.url || getBadge(`${data?.[rowIndex]?.abbreviation}`)}
                    alt={data?.[rowIndex]?.abbreviation}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = getBadge(`${data?.[rowIndex]?.abbreviation}`);
                    }}
                    variant="rounded"
                    style={{
                      height: "35px",
                      maxWidth: "50px",
                      objectFit: "contain",
                    }}
                  />
                );
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "activationQuarter",
            label: "Activation Quarter",
            options: {
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "Status",
            label: "Status",
            options: {
              sort: false,
              customBodyRender: (value) =>
                value ? (
                  <LbChip
                    label={ORGANIZATION_STATUS_OPTIONS._[value]}
                    color={
                      {
                        [ORGANIZATION_STATUS_OPTIONS.key.ACTIVE]: theme.palette.success.main,
                        [ORGANIZATION_STATUS_OPTIONS.key.MAINTENANCE]: theme.palette.warning.dark,
                      }[value]
                    }
                  />
                ) : null,
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          "Name",
          "Users",
          "Vendors",
          "Contracts",
          "Templates",
          "Documents",
          {
            name: "dataSize",
            label: "Data Size",
            options: {
              customBodyRender: (value) => {
                return filesize(value).human("si");
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "modules",
            label: "Modules",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack direction="row" spacing={1}>
                    {Children.toArray(
                      map(
                        filter(keys(value), (item) => value[item].active),
                        (item) => <LbStatusChip status={upperCase(item)} />
                      )
                    )}
                  </Stack>
                );
              },
            },
          },
          "Seller Reference",
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (value, { rowIndex }) => (
                <LbMoreMenu
                  menus={[
                    {
                      label: "Edit",
                      icon: FiEdit3,
                      path: generatePath(organizationsClientUrls.edit.base, {
                        organizationId: value,
                      }),
                    },
                    {
                      label: "Modules",
                      icon: RiApps2Line,
                      path: generatePath(organizationsClientUrls.modules.base, {
                        organizationId: value,
                        module: "iam",
                      }),
                    },
                    {
                      label: "Nomenclatures",
                      icon: FontDownload,
                      path: generatePath(organizationsClientUrls.nomenclatures.base, {
                        organizationId: value,
                      }),
                    },
                    {
                      label: "Reinvite",
                      icon: Send,
                      onClick: () => reinvite(value),
                    },
                    data?.[rowIndex]?.status === ORGANIZATION_STATUS_OPTIONS.key.ACTIVE
                      ? {
                          label: "Enable maintenance mode",
                          icon: Construction,
                          onClick: () => enableMaintenanceMode(value),
                        }
                      : {
                          label: "Disable maintenance mode",
                          icon: CellTowerOutlined,
                          onClick: () => disableMaintenanceMode(value),
                        },
                    {
                      label: "Delete Organization",
                      icon: Delete,
                      labelProps: { color: "error" },
                      iconProps: { color: "error" },
                      onClick: () => deactivate(value),
                    },
                  ]}
                />
              ),
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          searchPlaceholder: "Search Organizations",
          customToolbar: ({ displayData }) => {
            return (
              <LbButton sx={{ order: -1 }} variant="outlined" component={Link} to={organizationsClientUrls.add.base}>
                Onboard
              </LbButton>
            );
          },
        }}
      />
      <Outlet />
    </Fragment>
  );
}

export default engine(Organizations);
export { default as AddInviteOrganization } from "./AddInvite";
export { default as AddOrganization } from "./AddInvite/Add";
export { default as InviteOrganization } from "./AddInvite/Invite";
export { default as EditOrganization } from "./Edit";
export { default as OrganizationModules } from "./Modules";
