import {
  LbActionFooter,
  LbAutocomplete,
  LbButton,
  LbLoading,
  LbSearch,
  LbTitle,
  documentsEndpoints,
  formatToLableValueObj,
} from "@lb/frontend";
import { Close, Delete } from "@mui/icons-material";
import {
  AppBar,
  Drawer,
  IconButton,
  Stack,
  TableCell,
  Toolbar,
  Typography,
} from "@mui/material";
import { FieldArray, FormikProvider } from "formik";
import { map } from "lodash";
import React, { Children } from "react";
import engine from "./engine";

function UploadEdit({
  isLoading,
  controller,
  handleClose,
  organizationId,
  formik,
}) {
  return (
    <Drawer
      open={controller?._id}
      anchor="right"
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "#ECF6FE",
        }}
      >
        <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
          <LbTitle
            backAction={handleClose}
            icon={<Close color="primary" />}
            iconRight
            typoProps={{ color: "primary.main" }}
            tooltip="Back to userss list"
          >
            Manage Tags for {controller?.name}
          </LbTitle>
        </Toolbar>
      </AppBar>

      {isLoading && controller?._id ? (
        <LbLoading height="60vh" />
      ) : (
        <FormikProvider value={formik}>
          <Stack
            height="100%"
            component={"form"}
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={3.5} px={2} pt={2} pb={10}>
              <FieldArray
                name="tags"
                render={(arrayHelpers) => (
                  <table>
                    {Children.toArray(
                      map(formik.values.tags, (val, index) => {
                        return (
                          <tr>
                            <td>
                              <Typography
                                display="flex"
                                variant="h6"
                                alignItems="center"
                                color={"#ccc"}
                              >
                                #{index + 1}&nbsp;&nbsp;
                              </Typography>
                            </td>
                            <td width={"50%"}>
                              <LbSearch
                                idKey="_id"
                                baseUrl="drm"
                                label="Tag Name"
                                optionLabel="name"
                                queryParamName="search"
                                placeholder="Select Tag"
                                name={`tags.${index}.tag`}
                                additionalProperties={["options"]}
                                requestUrl={documentsEndpoints.tags.search(
                                  organizationId
                                )}
                                omitOptions={map(formik.values.tags, "tag._id")}
                              />
                            </td>
                            <TableCell width={"50%"} sx={{ border: 0 }}>
                              <LbAutocomplete
                                multiple
                                label="Values"
                                placeholder="Select values"
                                name={`tags.${index}.values`}
                                options={formatToLableValueObj(
                                  formik.values.tags[index].tag?.options
                                )}
                              />
                            </TableCell>
                            <td>
                              <IconButton
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                sx={{ border: 2, borderRadius: "7px" }}
                                size="small"
                                color="error"
                              >
                                <Delete />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })
                    )}
                    <tr>
                      <td
                        colSpan={4}
                        align="center"
                        style={{ paddingTop: "1rem" }}
                      >
                        <LbButton
                          size="large"
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({ tag: "", values: [] })
                          }
                          sx={{ borderStyle: "dashed" }}
                        >
                          Add New Tag
                        </LbButton>
                      </td>
                    </tr>
                  </table>
                )}
              />
            </Stack>

            <LbActionFooter
              sx={{
                mt: "auto!important",
                position: "sticky",
                bottom: 0,
                justifyContent: "space-between",
              }}
            >
              <LbButton
                type="button"
                disabled={formik.isSubmitting}
                onClick={handleClose}
                color="warning"
              >
                Cancel
              </LbButton>

              <Stack direction="row" justifyContent={"end"} spacing={3}>
                <LbButton
                  color="error"
                  type="button"
                  disabled={formik.isSubmitting}
                  onClick={formik.resetForm}
                  hidden={true}
                >
                  Reset
                </LbButton>
                <LbButton
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={!formik.dirty}
                >
                  {controller?._id ? "Update" : "Save"}
                </LbButton>
              </Stack>
            </LbActionFooter>
          </Stack>
        </FormikProvider>
      )}
    </Drawer>
  );
}

export default engine(UploadEdit);
