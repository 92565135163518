import { LbActionFooter, LbButton, LbFileUploader } from "@lb/frontend";
import { DOC_TYPES } from "@lb/utils";
import { Grid, Toolbar } from "@mui/material";
import { map, toPairs } from "lodash";
import React, { Children, Fragment, useState } from "react";
import engine from "./engine";

function Documents({
  data: { documents: data = {} },
  uploadOrgDoc,
  previousStep,
  nextStep,
}) {
  return (
    <Fragment>
      <Grid container spacing={3}>
        {Children.toArray(
          map(
            toPairs(DOC_TYPES.forOrg()._),
            function FileInput([docType, docName]) {
              const [uploading, setUploading] = useState(false);
              return (
                <Grid item xs={6}>
                  <LbFileUploader
                    loading={uploading}
                    doc={data[docType]}
                    label={docName}
                    primaryText={docName}
                    onChange={(file) =>
                      uploadOrgDoc(docType, file, setUploading)
                    }
                  />
                </Grid>
              );
            }
          )
        )}
      </Grid>
      <Toolbar />

      <LbActionFooter>
        <LbButton type="button" color="warning" onClick={previousStep}>
          Back
        </LbButton>

        <LbButton type={"button"} onClick={() => nextStep?.()}>
          Next
        </LbButton>
      </LbActionFooter>
    </Fragment>
  );
}

export default engine(Documents);
