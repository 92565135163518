import { Stack } from "@mui/material";
import React from "react";

function Contracts({ data }) {
  return (
    <Stack spacing={3}>
      {/*
       * //TODO: Default reviewers is obsolete
       * //! Functionlity moved to REVIEWER MATRIX
       * //TODO: Safe to remove after JULY 2024
       */}
      {/* <section>
        <LbTitle
          typoProps={{
            display: "flex",
            alignItems: "center",
            color: "text.secondary",
            columnGap: 0.5,
          }}
          stackProps={{ mb: 2 }}
        >
          <PeopleOutline />
          Default Reviewers:
        </LbTitle>

        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={2}>
            <LbSwitch
              name="modules.clm.contracts.reviewers.admin"
              label="Admin"
            />
          </Grid>
          <Grid item xs={3}>
            <LbSwitch
              name="modules.clm.contracts.reviewers.hods"
              label="Head of Department"
            />
          </Grid>
          <Grid item xs={3}>
            <LbSwitch
              name="modules.clm.contracts.reviewers.moduleAdmins"
              label="Module Admin"
            />
          </Grid>
          <Grid item xs={4}>
            <LbSearch
              multiple
              label="Custom Reviewers"
              name="modules.clm.contracts.reviewers.custom"
              optionLabel="email"
              placeholder="Search users"
              queryParamName="search"
              requestUrl={usersEndpoints.search(data._id)}
              idKey="_id"
              baseUrl="iam"
            />
          </Grid>
        </Grid>
      </section> */}

      {/*
       * //TODO: Default signatories is obsolete
       * //! Functionlity moved to SIGNATORY MATRIX
       * //TODO: Safe to remove after JULY 2024
       */}
      {/* <section>
        <LbTitle
          typoProps={{
            display: "flex",
            alignItems: "center",
            color: "text.secondary",
            columnGap: 0.5,
          }}
          stackProps={{ mb: 2 }}
        >
          <PeopleOutline />
          Default Signatories:
        </LbTitle>

        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={2}>
            <LbSwitch
              name="modules.clm.contracts.signatories.admin"
              label="Admin"
            />
          </Grid>
          <Grid item xs={3}>
            <LbSwitch
              name="modules.clm.contracts.signatories.hods"
              label="Head of Department"
            />
          </Grid>
          <Grid item xs={3}>
            <LbSwitch
              name="modules.clm.contracts.signatories.moduleAdmins"
              label="Module Admin"
            />
          </Grid>
          <Grid item xs={4}>
            <LbSearch
              multiple
              label="Custom Signatories"
              name="modules.clm.contracts.signatories.custom"
              optionLabel="email"
              placeholder="Search users"
              queryParamName="search"
              requestUrl={usersEndpoints.search(data._id)}
              idKey="_id"
              baseUrl="iam"
            />
          </Grid>
        </Grid>
      </section> */}
    </Stack>
  );
}

export default Contracts;
