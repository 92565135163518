import {
  LbActionFooter,
  LbAutocomplete,
  LbButton,
  LbSearch,
  LbTags,
  LbTitle,
  countryEndpoints,
} from "@lb/frontend";
import {
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES,
  INDUSTRY_OPTIONS,
  ORG_TYPES,
  masterClientUrls,
} from "@lb/utils";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import engine from "./engine";

function Add({ handleSubmit }) {
  return (
    <Fragment>
      <LbTitle
        link={masterClientUrls.clm.clauses.base}
        tooltip="Back to clauses list"
        stackProps={{ mb: 3 }}
      >
        Create Clause
      </LbTitle>

      <Formik
        initialValues={{
          names: [],
          industry: [],
          // countries: [],
          orgTypes: [],
          businessTypes: [],
          businessCategories: [],
        }}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <LbTags
                    name="names"
                    label="Clause Names"
                    placeholder="Enter clause names"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LbAutocomplete
                    multiple
                    name="industries"
                    label="Industries"
                    placeholder="Select industry"
                    options={INDUSTRY_OPTIONS.options}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <LbSearch
                    multiple
                    name="countries"
                    label="Countries"
                    optionLabel={"name"}
                    idKey={"code"}
                    queryParamName="search"
                    placeholder="Search country"
                    requestUrl={countryEndpoints.base}
                    baseUrl="auth"
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <LbAutocomplete
                    multiple
                    name="orgTypes"
                    label="Organization Types"
                    placeholder="Select organization types"
                    options={ORG_TYPES.labelValue}
                  />
                </Grid>

                <Grid item xs={6}>
                  <LbAutocomplete
                    multiple
                    name="businessTypes"
                    label="Business Types"
                    placeholder="Select business types"
                    options={BUSINESS_TYPES.labelValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LbAutocomplete
                    multiple
                    name="businessCategories"
                    label="Business Categories"
                    placeholder="Select business categories"
                    options={BUSINESS_CATEGORIES.labelValue}
                  />
                </Grid>
              </Grid>

              <LbActionFooter>
                <LbButton
                  type="button"
                  disabled={isSubmitting}
                  LinkComponent={Link}
                  to={masterClientUrls.clm.clauses.base}
                  color="warning"
                >
                  Cancel
                </LbButton>

                <LbButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={!dirty}
                >
                  Submit
                </LbButton>
              </LbActionFooter>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default engine(Add);
