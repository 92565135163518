import {
  LbActionFooter,
  LbButton,
  LbSearch,
  usersEndpoints,
} from "@lb/frontend";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { Fragment } from "react";

export default function COIConfig({ data }) {
  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          admin: data.modules?.coi?.admin || null,
        }}
      >
        {({ isSubmitting, errors, dirty }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <LbSearch
                    label="Admin"
                    name="admin"
                    optionLabel="email"
                    placeholder="Search users"
                    queryParamName="search"
                    requestUrl={usersEndpoints.search(data._id)}
                    idKey="_id"
                    baseUrl={"iam"}
                  />
                </Grid>
              </Grid>
              <LbActionFooter>
                <LbButton
                  disabled={!dirty}
                  type={"submit"}
                  loading={isSubmitting}
                >
                  Save
                </LbButton>
              </LbActionFooter>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
}
