import {
  LbActionFooter,
  LbButton,
  LbDatePicker,
  LbFileInput,
  LbLoading,
  LbSearch,
  LbTextArea,
  LbTextField,
  LbTitle,
  departmentEndpoints,
  usersEndpoints,
} from "@lb/frontend";
import { Close } from "@mui/icons-material";
import { AppBar, Drawer, Stack, Toolbar } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";
import engine from "./engine";

function UploadEdit({
  isLoading,
  controller,
  handleClose,
  organizationId,
  formik,
}) {
  return (
    <Drawer
      open={controller.open}
      anchor="right"
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "#ECF6FE",
        }}
      >
        <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
          <LbTitle
            backAction={handleClose}
            icon={<Close color="primary" />}
            iconRight
            typoProps={{ color: "primary.main" }}
            tooltip="Back to userss list"
          >
            {controller._id ? "Update" : "Upload"} Document
          </LbTitle>
        </Toolbar>
      </AppBar>

      {isLoading && controller._id ? (
        <LbLoading height="60vh" />
      ) : (
        <FormikProvider value={formik}>
          <Stack
            height="100%"
            component={"form"}
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={3.5} px={2} pt={2} pb={10}>
              <LbTextField
                required
                name="name"
                label="Name"
                placeholder="Enter name"
              />

              <LbDatePicker required name="eventDate" label="Event date" />

              <LbSearch
                required
                multiple
                label="Departments"
                name="departments"
                optionLabel="name"
                placeholder="Choose departments"
                queryParamName="name"
                requestUrl={departmentEndpoints.search(organizationId)}
                idKey="_id"
                baseUrl="iam"
                queryParams={{
                  overrideEmptyHodsCheck: true,
                }}
                starSelector
              />

              <LbSearch
                required
                multiple
                label="Roles"
                name="roles"
                optionLabel="name"
                placeholder="Choose Roles"
                queryParamName="search"
                requestUrl={usersEndpoints.roles.search(organizationId)}
                idKey="_id"
                baseUrl="iam"
                queryParams={{
                  includeModuleAdmins: true,
                }}
                starSelector
              />

              <LbFileInput required name="transcript" label="Transcript" />
              
              <LbTextArea
                name="description"
                label="Document description"
                placeholder="Enter document description"
              />

            </Stack>

            <LbActionFooter
              sx={{
                mt: "auto!important",
                position: "sticky",
                bottom: 0,
                justifyContent: "space-between",
              }}
            >
              <LbButton
                type="button"
                disabled={formik.isSubmitting}
                onClick={handleClose}
                color="warning"
              >
                Cancel
              </LbButton>

              <Stack direction="row" justifyContent={"end"} spacing={3}>
                <LbButton
                  color="error"
                  type="button"
                  disabled={formik.isSubmitting}
                  onClick={formik.resetForm}
                  hidden={true}
                >
                  Reset
                </LbButton>
                <LbButton
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={!formik.dirty}
                >
                  {controller._id ? "Update" : "Save"}
                </LbButton>
              </Stack>
            </LbActionFooter>
          </Stack>
        </FormikProvider>
      )}
    </Drawer>
  );
}

export default engine(UploadEdit);
