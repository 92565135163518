import { LbLoading, LbTitle } from "@lb/frontend";
import { LB_MODULES, organizationsClientUrls } from "@lb/utils";
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { map } from "lodash";
import React, { Children, createElement, Fragment } from "react";
import CLMConfig from "./CLM";
import COIConfig from "./COI";
import DRMConfig from "./DRM";
import engine from "./engine";
import GRCConfig from "./GRC";
import IAMConfig from "./IAM";

function Modules({
  data,
  formik,
  module,
  refetch,
  isLoading,
  changeModule,
  moduleLoading,
  toggleModuleActivation,
  updateModuleConfig,
}) {
  if (isLoading) return <LbLoading py={20} />;

  return (
    <Fragment>
      <Stack {...{ direction: "row" }} spacing={5}>
        <section>
          <LbTitle
            {...{
              stackProps: { mb: 2 },
              link: organizationsClientUrls.base,
              tooltip: "Back to organizations list",
            }}
          >
            {data?.abbreviation}
          </LbTitle>
          <List sx={{ py: 0 }}>
            {Children.toArray(
              map(LB_MODULES.labelValue, ({ logo, abbreviation, value }) => {
                return (
                  <Paper
                    sx={{ mb: 2, borderRadius: "8px", overflow: "hidden" }}
                    elevation={module === value ? 4 : 2}
                  >
                    <ListItem
                      disablePadding
                      secondaryAction={
                        value !== "iam" && (
                          <Fragment>
                            {moduleLoading[value] ? (
                              <CircularProgress size={25} sx={{ mr: 2 }} />
                            ) : (
                              <Switch
                                checked={data?.modules?.[value]?.active}
                                onChange={(event, newVal) => {
                                  toggleModuleActivation(value, newVal);
                                }}
                              />
                            )}
                          </Fragment>
                        )
                      }
                    >
                      <ListItemButton
                        disabled={
                          value === "iam"
                            ? false
                            : !data?.modules?.[value]?.active
                        }
                        onClick={() => changeModule(value)}
                        selected={module === value}
                      >
                        <ListItemAvatar>
                          <Avatar src={logo} />
                        </ListItemAvatar>
                        <ListItemText primary={abbreviation} sx={{ pr: 5 }} />
                      </ListItemButton>
                    </ListItem>
                  </Paper>
                );
              })
            )}
          </List>
        </section>

        <Box width={"100%"}>
          {createElement(
            {
              iam: IAMConfig,
              clm: CLMConfig,
              grc: GRCConfig,
              coi: COIConfig,
              drm: DRMConfig,
            }[module] || IAMConfig,
            { data, refetch, updateModuleConfig }
          )}
        </Box>
      </Stack>
    </Fragment>
  );
}

export default engine(Modules);
