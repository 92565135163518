import React from "react";
import { useState } from "react";
import { useReactQuery, getRequest, clauseEndpoints } from "@lb/frontend";

const engine =
  (Component) =>
  ({ ...props }) => {
    const [searchVal, setSearchVal] = useState("");
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [organization, setOrganization] = useState({});

    const { data: { data, total } = {}, isLoading } = useReactQuery({
      queryKeys: [
        "clauses.groups",
        pagination.page,
        pagination.limit,
        searchVal,
        organization._id,
      ],
      apiCall: () =>
        getRequest({
          url: clauseEndpoints.getAll(organization._id),
          params: {
            pageNo: pagination.page,
            limit: pagination.limit,
            search: searchVal,
          },
          baseUrl: "clm",
        }),
    });

    return (
      <Component
        {...props}
        {...{
          data,
          isLoading,
          setSearchVal,
          pagination: {
            ...pagination,
            total,
          },
          setPagination,
          organization,
          setOrganization,
        }}
      />
    );
  };

export default engine;
