import React, { Fragment } from "react";
import engine from "./engine";
import { LbActionFooter, LbAutocomplete, LbButton, LbLoading, LbTags } from "@lb/frontend";
import { FormikProvider } from "formik";
import { Grid } from "@mui/material";
import { BUSINESS_CATEGORIES, BUSINESS_TYPES, INDUSTRY_OPTIONS, ORG_TYPES, masterClientUrls } from "@lb/utils";
import { Link } from "react-router-dom";

function Metadata({ isLoading, formik }) {
  return (
    <Fragment>
      {isLoading ? (
        <LbLoading py={30} />
      ) : (
        <FormikProvider value={formik}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <LbTags
                name="names"
                label="Clause Names"
                placeholder="Enter clause names"
              />
            </Grid>
            <Grid item xs={12}>
              <LbAutocomplete
                multiple
                name="industries"
                label="Industries"
                placeholder="Select industry"
                options={INDUSTRY_OPTIONS.options}
              />
            </Grid>

            <Grid item xs={6}>
              <LbAutocomplete
                multiple
                name="orgTypes"
                label="Organization Types"
                placeholder="Select organization types"
                options={ORG_TYPES.labelValue}
              />
            </Grid>

            <Grid item xs={6}>
              <LbAutocomplete
                multiple
                name="businessTypes"
                label="Business Types"
                placeholder="Select business types"
                options={BUSINESS_TYPES.labelValue}
              />
            </Grid>
            <Grid item xs={6}>
              <LbAutocomplete
                multiple
                name="businessCategories"
                label="Business Categories"
                placeholder="Select business categories"
                options={BUSINESS_CATEGORIES.labelValue}
              />
            </Grid>
          </Grid>

          <LbActionFooter>
            <LbButton
              type="button"
              disabled={formik.isSubmitting}
              LinkComponent={Link}
              to={masterClientUrls.clm.clauses.base}
              color="warning"
            >
              Cancel
            </LbButton>

            <LbButton
              type="submit"
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
              disabled={!formik.dirty}
            >
              Submit
            </LbButton>
          </LbActionFooter>
        </FormikProvider>
      )}
    </Fragment>
  );
}

export default engine(Metadata);
