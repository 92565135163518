import {
  LbActionFooter,
  LbButton,
  LbTextArea,
  LbTextField,
  LbTitle,
  LbLoading,
} from "@lb/frontend";
import { Close } from "@mui/icons-material";
import { AppBar, Drawer, Stack, Toolbar, alpha, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import * as Yup from "yup";
import engine from "./engine";

function AddEdit({
  handleClose,
  handleSubmit,
  groupId,
  orgId,
  groupData,
  isLoading,
}) {
  const theme = useTheme();
  return (
    <Fragment>
      <Drawer
        open
        anchor="right"
        onClose={handleClose}
        PaperProps={{ sx: { width: "30%" } }}
      >
        <AppBar
          position="sticky"
          elevation={0}
          sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
        >
          <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
            <LbTitle
              backAction={handleClose}
              icon={<Close color="primary" />}
              iconRight
              typoProps={{ color: "primary.main" }}
              tooltip="Back to groups list"
            >
              {groupId ? "Edit" : "Create"} Clause Group
            </LbTitle>
          </Toolbar>
        </AppBar>
        <Formik
          enableReinitialize
          initialValues={{
            name: groupData?.name || "",
            description: groupData.description || "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, dirty }) => {
            if (isLoading && groupId) return <LbLoading py={20} />;

            return (
              <Stack height={"100%"} component={Form}>
                <Stack spacing={1} px={2} pt={4}>
                  <LbTextField
                    name="name"
                    label="Clause Group Name"
                    placeholder="Enter clause group name"
                    boxProps={{ mb: 3 }}
                  />

                  <LbTextArea
                    name="description"
                    label="Clause Group Description"
                    placeholder="Enter clause group description"
                  />
                </Stack>
                <LbActionFooter
                  sx={{
                    mt: "auto!important",
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <LbButton
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleClose}
                    color="warning"
                  >
                    Cancel
                  </LbButton>

                  <LbButton
                    type="submit"
                    loading={isSubmitting}
                    disabled={!dirty}
                  >
                    {groupId ? "Update" : "Submit"}
                  </LbButton>
                </LbActionFooter>
              </Stack>
            );
          }}
        </Formik>
      </Drawer>
    </Fragment>
  );
}

export default engine(AddEdit);
