import React from "react";
import OrganizationForm from "../Form";
import engine from "./engine";

function EditOrganization({ organizationId }) {
  return (
    <div>
      <OrganizationForm {...{ organizationId }} />
    </div>
  );
}

export default engine(EditOrganization);
