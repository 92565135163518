import { LbButton, LbDataTable, LbMoreMenu, LbStatusChip } from "@lb/frontend";
import { Delete, ReplayRounded } from "@mui/icons-material";
import { CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { map } from "lodash";
import React, { Children, Fragment } from "react";
import { FiEdit3 } from "react-icons/fi";
import AddEdit from "./AddEdit";
import engine from "./engine";

function Tags({
  remove,
  isLoading,
  data,
  pagination,
  setPagination,
  addEditDialogController,
  setAddEditDialogController,
  refetch,
  isFetching,
}) {
  return (
    <Fragment>
      <LbDataTable
        {...{ isLoading, pagination, setPagination }}
        height={"100%"}
        title={"Tags"}
        data={map(data, ({ name, options, _id }) => [name, options, _id])}
        columns={[
          {
            name: "tagName",
            label: "Tag Name",
            options: {
              customBodyRender: (value) => (
                <Typography noWrap variant="body2">
                  {value}
                </Typography>
              ),
            },
          },
          {
            name: "options",
            label: "Tag Options",
            options: {
              customBodyRender: (value) => (
                <Stack
                  direction="row"
                  columnGap={1}
                  rowGap={1}
                  flexWrap={"wrap"}
                >
                  {Children.toArray(
                    map(value, (item) => {
                      return <LbStatusChip status={item} />;
                    })
                  )}
                </Stack>
              ),
            },
          },
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (value) => (
                <LbMoreMenu
                  menus={[
                    {
                      label: "Edit",
                      icon: FiEdit3,
                      onClick: () =>
                        setAddEditDialogController({ open: true, _id: value }),
                    },
                    {
                      label: "Delete",
                      icon: Delete,
                      labelProps: { color: "error" },
                      iconProps: { color: "error" },
                      onClick: () => remove(value),
                    },
                  ]}
                />
              ),
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          filter: false,
          searchPlaceholder: "Search Organizations",
          customToolbar: ({ displayData }) => {
            return (
              <Fragment>
                {isFetching ? (
                  <IconButton onClick={refetch}>
                    <CircularProgress size={18} thickness={5} />
                  </IconButton>
                ) : (
                  <Tooltip title="Reload">
                    <IconButton onClick={refetch}>
                      <ReplayRounded
                        sx={{ "&:hover": { color: "primary.main" } }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <LbButton
                  sx={{ order: -1 }}
                  onClick={() => setAddEditDialogController({ open: true })}
                >
                  Create New Tag
                </LbButton>
              </Fragment>
            );
          },
        }}
      />

      <AddEdit
        controller={{
          ...addEditDialogController,
          set: setAddEditDialogController,
        }}
      />
    </Fragment>
  );
}

export default engine(Tags);
