import {
  LbActionFooter,
  LbButton,
  LbLoading,
  LbPatternField,
  LbSearch,
  LbTextArea,
  LbTextField,
  LbTitle,
  orgEndpoints,
} from "@lb/frontend";
import { getInitials, masterClientUrls } from "@lb/utils";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProvider } from "formik";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import engine from "./engine";

function AddEditTemplate({
  handleClose,
  handleSubmit,
  templateId,
  templateData,
  isLoading,
  formik,
}) {
  return (
    <Fragment>
      <Grid container justifyContent={"space-between"} mb={5}>
        <Grid item>
          <LbTitle
            tooltip="Back to templates list"
            link={masterClientUrls.clm.templates.base}
          >
            {templateId ? "Edit" : "Create"} Template
          </LbTitle>
        </Grid>
        <Grid item xs={3}>
          <FormikProvider value={formik}>
            <LbSearch
              name="organization"
              label="Organization"
              optionLabel="name"
              queryParamName="search"
              placeholder="Search organizations"
              requestUrl={orgEndpoints.search}
              baseUrl="master"
              // disabled={templateId}
            />
          </FormikProvider>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        initialValues={{
          name: templateData?.name || "",
          description: templateData?.description || "",
          content: templateData?.content || "",
          abbreviation: templateData?.abbreviation || "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, values, setFieldValue }) => {
          if (isLoading && templateId) return <LbLoading py={20} />;

          return (
            <Stack height={"100%"} component={Form} mb={20}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <LbTextField
                    name="name"
                    label="Template Name"
                    placeholder="Enter template name"
                  />
                </Grid>
                <Grid item md={2}>
                  <LbPatternField
                    name="abbreviation"
                    label="Abbreviation"
                    placeholder="Enter abbreviation"
                    pattern={"$$$$$$$$$$$$$$"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            disabled={!values?.name}
                            onClick={() =>
                              setFieldValue(
                                "abbreviation",
                                getInitials(values?.name)
                              )
                            }
                            size="small"
                            variant="text"
                            color="info"
                          >
                            Auto
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LbTextArea
                    name="description"
                    label="Template Description"
                    placeholder="Enter template description"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      ".ql-toolbar": { borderRadius: "8px 8px 0 0" },
                      ".ql-container": { borderRadius: "0 0 8px 8px" },
                      aspectRatio: 1 / 1.4142,
                      display: "inline",
                    }}
                  >
                    <Typography color="text.secondary" variant="body2">
                      Content:
                    </Typography>
                    <ReactQuill
                      theme="snow"
                      value={values.content}
                      onChange={(val) => setFieldValue("content", val)}
                      style={{ marginTop: 0 }}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ align: [] }],
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ["table"],
                        ],
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <LbActionFooter>
                <LbButton
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleClose}
                  color="warning"
                >
                  Cancel
                </LbButton>

                <LbButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={!dirty}
                >
                  {templateId ? "Update" : "Submit"}
                </LbButton>
              </LbActionFooter>
            </Stack>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default engine(AddEditTemplate);
