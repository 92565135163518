import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/system";

export const NestedCheckboxLabel = styled(FormControlLabel)({
  position: "relative",
  "&:before": {
    left: -10,
    top: "50%",
    width: "1.5%",
    content: "''",
    position: "absolute",
    borderTop: "2px dashed #ccc",
    transform: "translateY(-50%)",
  },
});
