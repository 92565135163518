import { LbButton, LbStatusChip } from "@lb/frontend";
import { ORG_STRUCTURE, lbBlue } from "@lb/utils";
import { Box, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { map, omit, startCase, toPairs } from "lodash";
import React, { Children, Fragment } from "react";
import engine from "./engine";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Delete,
  DragHandle,
  DragIndicator,
  Edit,
  Settings,
} from "@mui/icons-material";

function Variables({ handleDragEnd, formik, addNew }) {
  return (
    <Fragment>
      <Grid container>
        <Grid item>
          <LbButton size="large" onClick={addNew} sx={{ width: "100%", mb: 3 }}>
            Add new variable to the list.
          </LbButton>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="list">
              {(provided, snapshot) => {
                return (
                  <Grid
                    container
                    spacing={2}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {Children.toArray(
                      map(formik.values.content, (item, index) => {
                        return (
                          <Draggable
                            {...{
                              index,
                              key: item.name,
                              id: item.name,
                              draggableId: item.name,
                            }}
                          >
                            {(provided, snapshot) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Paper
                                    sx={{
                                      boxSizing: "border-box",
                                      py: 1,
                                      px: 2,
                                      border: 2,
                                      borderColor: snapshot.isDragging
                                        ? "primary.main"
                                        : "#fff",
                                      borderRadius: "8px",
                                      backgroundColor: snapshot.isDragging
                                        ? lbBlue.shade14
                                        : "#fff",
                                      "&:hover": {
                                        backgroundColor: lbBlue.shade15,
                                      },
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      spacing={3}
                                      alignItems={"center"}
                                    >
                                      <Typography variant="h4" color="grey.300">
                                        #{index + 1}
                                      </Typography>

                                      <Box flex={1}>
                                        <Typography
                                          variant="body2"
                                          fontWeight="bolder"
                                        >
                                          {item.label}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="text.secondary"
                                        >
                                          #{item.name}
                                        </Typography>
                                      </Box>

                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <IconButton color="error">
                                          <Delete fontSize="small" />
                                        </IconButton>

                                        <IconButton>
                                          <Settings fontSize="small" />
                                        </IconButton>
                                        {snapshot.isDragging ? (
                                          <DragHandle
                                            sx={{
                                              cursor: "grab",
                                              color: "grey.500",
                                            }}
                                          />
                                        ) : (
                                          <DragIndicator
                                            sx={{
                                              cursor: "grab",
                                              color: "grey.500",
                                            }}
                                          />
                                        )}
                                      </Stack>
                                    </Stack>
                                  </Paper>
                                </Grid>
                              );
                            }}
                          </Draggable>
                        );
                      })
                    )}
                    {provided.placeholder}
                  </Grid>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>

      {/* <Typography mb={2}>Predefined Organization Variables:</Typography>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        {Children.toArray(
          map(
            toPairs({
              ...omit(ORG_STRUCTURE.METADATA, [
                "adminEmail",
                "adminName",
                "administrativeDepartmentName",
                "administrativeDepartmentDescription",
              ]),
              ...omit(ORG_STRUCTURE.STATUTORY_DETAILS, ["governmentId"]),
              ...ORG_STRUCTURE.STATUTORY_DETAILS.governmentId,
            }),
            ([key, value]) => {
              return <LbStatusChip status={startCase(key)}></LbStatusChip>;
            }
          )
        )}
      </Stack> */}
    </Fragment>
  );
}

export default engine(Variables);
