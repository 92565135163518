import { getThinScrollBar, LbSwitch, LbTitle } from "@lb/frontend";
import { VENDOR_STRUCTURE } from "@lb/utils";
import { SegmentRounded, StarsRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grid, List, Stack, Typography } from "@mui/material";
import { keys, map, omit, startCase } from "lodash";
import React, { Children } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

function InputFields({ formik }) {
  return (
    <div>
      <LbTitle
        typoProps={{
          display: "flex",
          alignItems: "center",
          color: "text.secondary",
          columnGap: 0.5,
        }}
        stackProps={{ mt: 2, mb: 3 }}
      >
        <SegmentRounded />
        Input Fields
      </LbTitle>
      <Grid container spacing={8}>
        {Children.toArray(
          map(
            [
              {
                key: "metadata",
                fields: keys(omit(VENDOR_STRUCTURE.METADATA, ["name", "email", "type", "abbreviation", "orgType", "location", "gender", "departments"])),
              },
              {
                key: "statutoryDetails",
                fields: ["tdsSection", "udyamMsme", ...keys(omit(VENDOR_STRUCTURE.STATUTORY_DETAILS.governmentId, ["msme", "udyam"]))],
              },
              {
                key: "documents",
                fields: ["udyamMsme", ...keys(omit(VENDOR_STRUCTURE.DOCUMENTS.documents, ["msme", "udyam"]))],
              },
              {
                key: "commercials",
                fields: [],
              },
            ],
            ({ key, fields }) => {
              return (
                <Grid item xs={3}>
                  <LbSwitch name={`modules.iam.vendors.inputFields.${key}`} label={startCase(key)} />

                  <List sx={{ mt: 1, maxHeight: 300, overflowY: "scroll", ...getThinScrollBar() }} disablePadding>
                    {Children.toArray(
                      map(fields, (item) => {
                        return (
                          <Stack direction={"row"} justifyContent={"space-between"}>
                            <FormControlLabel
                              value="end"
                              sx={{ pl: 1 }}
                              control={
                                <Checkbox
                                  icon={<FiEyeOff fontSize={18} />}
                                  checkedIcon={<FiEye fontSize={18} />}
                                  checked={Boolean(formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item])}
                                  onChange={(event) => {
                                    formik.setFieldValue(`modules.iam.vendors.inputFields.${key}.${item}`, event.target.checked);
                                  }}
                                  color={
                                    formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item]
                                      ? formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item].required
                                        ? "error"
                                        : "default"
                                      : "default"
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  color={
                                    formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item]
                                      ? formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item].required
                                        ? "error"
                                        : "textPrimary"
                                      : "textSecondary"
                                  }
                                  sx={{
                                    userSelect: "none",
                                    textDecoration: formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item] ? "none" : "line-through",
                                  }}
                                >
                                  {startCase(item)}
                                </Typography>
                              }
                              labelPlacement="end"
                            />
                            <Checkbox
                              disabled={!formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item]}
                              checked={Boolean(formik.values.modules?.iam?.vendors?.inputFields?.[key]?.[item]?.required)}
                              onChange={(event) => formik.setFieldValue(`modules.iam.vendors.inputFields.${key}.${item}.required`, event.target.checked)}
                              size="small"
                              color="error"
                              checkedIcon={<StarsRounded />}
                            />
                          </Stack>
                        );
                      })
                    )}
                  </List>
                </Grid>
              );
            }
          )
        )}
      </Grid>
    </div>
  );
}

export default InputFields;
