import {
  clauseEndpoints,
  getRequest,
  putRequest,
  useHash,
  useReactQuery,
} from "@lb/frontend";
import { Notes } from "@mui/icons-material";
import { useFormik } from "formik";
import { findIndex, includes, omit } from "lodash";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const steps = [
  { label: "Metadata", icon: Notes, value: "metadata" },
  {
    label: "Variables",
    icon: Notes,
    value: "variables",
  },
  // { label: "Preview", icon: ArticleOutlined, value: "preview" },
];

const engine =
  (Component) =>
  ({ ...props }) => {
    const { hash, setHash } = useHash();
    const { clauseId, orgId } = useParams();

    useEffect(() => {
      if (!includes(["metadata", "variables"], hash)) setHash("metadata");
    }, [hash]);

    const nextStep = () => {
      const currentIndex = findIndex(steps, { value: hash });
      setHash(steps[currentIndex + 1]?.value);
    };

    const previousStep = () => {
      const currentIndex = findIndex(steps, { value: hash });
      setHash(steps[currentIndex - 1]?.value);
    };

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: ["clause", clauseId, orgId],
      apiCall: () =>
        getRequest({
          url: clauseEndpoints.getById(clauseId, orgId || ""),
          baseUrl: "clm",
        }),
      options: {
        enabled: Boolean(clauseId),
        cacheTime: 0,
      },
    });

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: omit(data, "_id", "__v", "createdAt", "updatedAt"),
      onSubmit: (values, { setSubmitting }) => {
        putRequest({
          url: clauseEndpoints.update(clauseId, orgId || ""),
          data: values,
          baseUrl: "clm",
        })
          .then(async () => {
            await refetch();
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

    return (
      <Component
        {...props}
        {...{
          clauseId,
          orgId,
          isLoading,
          data,
          formik,
          steps,
          nextStep,
          previousStep,
          hash,
          setHash,
        }}
      />
    );
  };

export default engine;
