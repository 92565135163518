import {
  LbActionFooter,
  LbButton,
  LbPatternField,
  LbTextArea,
  LbTextField,
} from "@lb/frontend";
import { getInitials } from "@lb/utils";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";

function Metadata({ data, handleSubmit }) {
  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          name: data?.name || "",
          description: data?.description || "",
          content: data?.content || "",
          abbreviation: data?.abbreviation || "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, values, setFieldValue, resetForm }) => {
          return (
            <Stack height={"100%"} component={Form} mb={20}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <LbTextField
                    name="name"
                    label="Template Name"
                    placeholder="Enter template name"
                  />
                </Grid>
                <Grid item md={2}>
                  <LbPatternField
                    name="abbreviation"
                    label="Abbreviation"
                    placeholder="Enter abbreviation"
                    pattern={"$$$$$$$$$$$$$$"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            disabled={!values?.name}
                            onClick={() =>
                              setFieldValue(
                                "abbreviation",
                                getInitials(values?.name)
                              )
                            }
                            size="small"
                            variant="text"
                            color="info"
                          >
                            Auto
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LbTextArea
                    name="description"
                    label="Template Description"
                    placeholder="Enter template description"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      ".ql-toolbar": { borderRadius: "8px 8px 0 0" },
                      ".ql-container": { borderRadius: "0 0 8px 8px" },
                      aspectRatio: 1 / 1.4142,
                      display: "inline",
                    }}
                  >
                    <Typography color="text.secondary" variant="body2">
                      Content:
                    </Typography>

                    <ReactQuill
                      theme="snow"
                      value={values.content}
                      onChange={(val) => setFieldValue("content", val)}
                      style={{ marginTop: 0 }}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ align: [] }],
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ["table"],
                        ],
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <LbActionFooter>
                <LbButton
                  type="button"
                  disabled={isSubmitting}
                  onClick={resetForm}
                  color="warning"
                >
                  Cancel
                </LbButton>

                <LbButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={!dirty}
                >
                  Submit
                </LbButton>
              </LbActionFooter>
            </Stack>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default Metadata;
