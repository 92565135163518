import { useEffect } from "react";
import { ORG_STRUCTURE } from "@lb/utils";
import { findIndex, includes, keys, omit, pick } from "lodash";
import { getObjectDifference, getRequest, putRequest, useHash, useReactQuery, orgEndpoints } from "@lb/frontend";
import { ColorLensSharp, DocumentScannerOutlined, Notes, NotificationsActiveOutlined, SecurityOutlined, WarehouseOutlined } from "@mui/icons-material";

const steps = [
  { label: "Metadata", icon: Notes, value: "metadata" },
  {
    label: "Statutory Details",
    icon: SecurityOutlined,
    value: "statutory-details",
  },
  { label: "Documents", icon: DocumentScannerOutlined, value: "documents" },
  {
    label: "Notifications",
    icon: NotificationsActiveOutlined,
    value: "notifications",
  },
  { label: "Storage", icon: WarehouseOutlined, value: "storage" },
  { label: "Colors", icon: ColorLensSharp, value: "colors" },
];

const engine =
  (Component) =>
  ({ ...props }) => {
    const { organizationId } = props;

    const { hash, setHash } = useHash();

    useEffect(() => {
      if (!includes(["metadata", "statutory-details", "documents", "notifications", "storage", "colors"], hash)) setHash("metadata");
    }, [hash]);

    const {
      data: {
        data = {
          ...ORG_STRUCTURE.METADATA,
          ...ORG_STRUCTURE.GOVERNEMNT_ID,
          ...ORG_STRUCTURE.DOCUMENTS,
          ...ORG_STRUCTURE.SIGNATORIES,
        },
      } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: ["organization", organizationId],
      apiCall: () =>
        getRequest({
          url: orgEndpoints.getById(organizationId).base,
          baseUrl: "master",
        }),
      options: {
        enabled: Boolean(organizationId),
      },
    });

    const submitHandler = (values, { setSubmitting, resetForm }) => {
      let updatedValues = {
        ...getObjectDifference(
          values,
          pick(
            data,
            keys(
              omit(
                ORG_STRUCTURE[
                  {
                    metadata: "METADATA",
                    "statutory-details": "STATUTORY_DETAILS",
                    signatories: "SIGNATORIES",
                    colors: "COLORS",
                    storage: "STORAGE",
                  }[hash] || "METADATA"
                ],
                ["otherAddresses"]
              )
            )
          )
        ),
      };

      putRequest({
        url: orgEndpoints.update(organizationId),
        data: {
          ...updatedValues,
          ...(updatedValues.otherAddresses && {
            otherAddresses: values.otherAddresses,
          }),
          ...(updatedValues.location && {
            location: values.location,
          }),
          ...(updatedValues.colors && {
            colors: values.colors,
          }),
          ...(updatedValues.storage && {
            storage: values.storage,
          }),
        },
        baseUrl: "master",
      })
        .then((res) => {
          resetForm?.({ values });
          setHash(
            {
              metadata: "statutory-details",
              "statutory-details": "documents",
              notifications: "storage",
              storage: "colors",
              colors: "colors",
            }[hash]
          );
          refetch();
        })
        .catch(() => {})
        .finally(() => setSubmitting?.(false));
    };

    const nextStep = () => {
      const currentIndex = findIndex(steps, { value: hash });
      setHash(steps[currentIndex + 1]?.value);
    };

    const previousStep = () => {
      const currentIndex = findIndex(steps, { value: hash });
      setHash(steps[currentIndex - 1]?.value);
    };

    return (
      <Component
        {...props}
        {...{
          data,
          refetch,
          isLoading,
          steps,
          hash,
          setHash,
          submitHandler,
          organizationId,
          previousStep,
          nextStep,
        }}
      />
    );
  };

export default engine;
