import React, { useEffect } from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    useEffect(() => {
      const receiveMessageFromChild = (event) => {
        console.log("🚀 ~ receiveMessageFromChild ~ event:", event);
        switch (event.data?.action) {
          case "update-organization-status-colors":
            props.submitHandler(event.data?.payload, {});
            break;
          default:
            break;
        }
      };

      window.addEventListener("message", receiveMessageFromChild);

      return () => {
        window.removeEventListener("message", receiveMessageFromChild);
      };
    }, []);

    return <Component {...props} {...{}} />;
  };

export default engine;
