import { useHash } from "@lb/frontend";
import React from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { hash: tab, setHash: setTab } = useHash();

    return <Component {...props} {...{ setTab, tab }} />;
  };

export default engine;
