import { LbTabs, LbButton, LbActionFooter } from "@lb/frontend";
import { Box } from "@mui/material";
import { FormikProvider } from "formik";
import React, { Fragment, createElement } from "react";
import Basic from "./Basic";
import engine from "./engine";
import Contracts from "./Contracts";

function CLMConfig({ data, setTab, tab, formik }) {
  return (
    <Fragment>
      <Box>
        <LbTabs
          activeTab={tab}
          handleTabChange={setTab}
          tabs={[
            {
              label: "Basic",
              value: "basic",
            },
            {
              label: "Contracts",
              value: "contracts",
            },
          ]}
        />
      </Box>

      <Box pt={2} pb={10}>
        <FormikProvider value={formik}>
          {createElement(
            {
              basic: Basic,
              contracts: Contracts,
            }[tab] || Basic,
            { data, formik }
          )}

          <LbActionFooter sx={{ zIndex: 5 }}>
            <LbButton
              color="error"
              type="button"
              onClick={formik.resetForm}
              disabled={formik.isSubmitting}
            >
              Reset
            </LbButton>
            <LbButton
              disabled={!formik.dirty}
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
            >
              Update
            </LbButton>
          </LbActionFooter>
        </FormikProvider>
      </Box>
    </Fragment>
  );
}
export default engine(CLMConfig);
