import { postRequest, orgEndpoints } from "@lb/frontend";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { organizationId, refetch } = props;

    const uploadOrgDoc = (type, file, setLoading) => {
      setLoading(true);
      var formdata = new FormData();
      formdata.append("docType", type);
      formdata.append("file", file);

      postRequest({
        url: orgEndpoints.uploadDoc(organizationId),
        data: formdata,
        baseUrl: "master",
      })
        .then((res) => {
          refetch();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return <Component {...props} {...{ uploadOrgDoc }} />;
  };

export default engine;
