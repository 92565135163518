import { LbButton, LbDataTable, LbMoreMenu } from "@lb/frontend";
import { masterClientUrls } from "@lb/utils";
import { Visibility } from "@mui/icons-material";
import { map } from "lodash";
import React, { Fragment } from "react";
import { FiEdit3 } from "react-icons/fi";
import { Link, Outlet, generatePath } from "react-router-dom";
import engine from "./engine";

function ClauseGroup({
  data,
  isLoading,
  setSearchVal,
  pagination,
  setPagination,
  organization,
}) {
  return (
    <Fragment>
      <LbDataTable
        title="Clauses Groups"
        {...{ pagination, isLoading, setSearchVal, setPagination }}
        height={"100%"}
        data={map(data, ({ name, description, _id } = {}) => [
          name,
          description,
          _id,
        ])}
        columns={[
          "Group Name",
          {
            name: "description",
            label: "Description",
            options: {
              filter: false,
            },
          },
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (_id) => {
                return (
                  <LbMoreMenu
                    menus={[
                      {
                        label: "Edit",
                        icon: FiEdit3,
                        path: generatePath(
                          masterClientUrls.clm.clauses.groups.edit.base,
                          {
                            groupId: _id,
                            orgId: organization._id || "public",
                          }
                        ),
                      },
                      {
                        label: "View",
                        icon: Visibility,
                        path: generatePath(
                          masterClientUrls.clm.clauses.groups.base
                        ),
                      },
                    ]}
                  />
                );
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          setTableProps: () => {
            return {
              size: "small",
            };
          },
          customToolbar: ({ displayData }) => {
            return (
              <LbButton
                sx={{ order: -1 }}
                variant="outlined"
                component={Link}
                to={masterClientUrls.clm.clauses.groups.add.base}
              >
                Add/ Create
              </LbButton>
            );
          },
        }}
      />
      <Outlet />
    </Fragment>
  );
}

export default engine(ClauseGroup);
export { default as AddEditClauseGroup } from "./AddEdit";
