import {
  LbLinkIcon,
  LbPatternField,
  LbSearch,
  LbSelect,
  LbTitle,
  departmentEndpoints,
  usersEndpoints,
} from "@lb/frontend";
import { AdminPanelSettings } from "@mui/icons-material";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function Basic({ data, formik }) {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <AdminPanelSettings />
            Admin Settings
          </LbTitle>
          <Stack spacing={3}>
            <LbSearch
              label="Admin"
              name="admin"
              optionLabel="email"
              placeholder="Search users"
              queryParamName="search"
              requestUrl={usersEndpoints.search(data._id)}
              idKey="_id"
              baseUrl="iam"
            />

            <LbSearch
              label="Department"
              name="administrativeDepartment"
              optionLabel="name"
              placeholder="Search departments"
              queryParamName="name"
              requestUrl={departmentEndpoints.search(data._id)}
              idKey="_id"
              baseUrl="iam"
              queryParams={{ overrideEmptyHodsCheck: true }}
            />
          </Stack>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={4}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <LbLinkIcon />
            Link Expiration
          </LbTitle>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography>Invitation:</Typography>
            </Grid>
            <Grid item xs={4}>
              <LbPatternField
                name="modules.iam.users.linkExpiry.invitation.duration"
                label="Duration"
                pattern="000"
              />
            </Grid>
            <Grid item xs={4}>
              <LbSelect
                name="modules.iam.users.linkExpiry.invitation.unit"
                label="Unit"
                options={[
                  { label: "Minutes", value: "m" },
                  { label: "Hours", value: "h" },
                  { label: "Days", value: "d" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>Signature:</Typography>
            </Grid>
            <Grid item xs={4}>
              <LbPatternField
                name="modules.iam.users.linkExpiry.signature.duration"
                label="Duration"
                pattern="000"
              />
            </Grid>
            <Grid item xs={4}>
              <LbSelect
                name="modules.iam.users.linkExpiry.signature.unit"
                label="Unit"
                options={[
                  { label: "Minutes", value: "m" },
                  { label: "Hours", value: "h" },
                  { label: "Days", value: "d" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Basic;
