import React, { Fragment } from "react";
import { ORG_STRUCTURE } from "@lb/utils";
import MetaData from "../../Form/MetaData";
import engine from "./engine";

function AddOrganization({submitHandler}) {
  return (
    <Fragment>
      <MetaData data={ORG_STRUCTURE.METADATA} {...{ submitHandler }} />
    </Fragment>
  );
}

export default engine(AddOrganization);
