import { useParams } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { organizationId } = useParams();

    return <Component {...props} {...{ organizationId }} />;
  };

export default engine;
