import { LbLinkIcon, LbPatternField, LbSelect, LbSwitch, LbTitle } from "@lb/frontend";
import { SettingsOutlined } from "@mui/icons-material";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import InputFields from "./InputFields";

function vendor({ data, formik }) {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <SettingsOutlined />
            Configurations
          </LbTitle>
          <Stack spacing={3}>
            <LbSwitch name="modules.iam.vendors.onboarding.invite" label="Vendor Onboarding using Invite" />
            <LbSwitch name="modules.iam.vendors.onboarding.bulk" label="Bulk Upload Vendors" />
            <LbSwitch name="modules.iam.vendors.onboarding.fetchDetailsUsingGstin" label="Fetch vendor details using GSTIN" />
            <LbSwitch name="modules.iam.vendors.periodicallyValidateGstStatus" label="Periodically validate for GST status" />{" "}
          </Stack>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <LbLinkIcon />
            Link Expiration
          </LbTitle>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Invitation:</Typography>
            </Grid>
            <Grid item xs={4}>
              <LbPatternField name="modules.iam.vendors.linkExpiry.invitation.duration" label="Duration" pattern="000" />
            </Grid>
            <Grid item xs={4}>
              <LbSelect
                name="modules.iam.vendors.linkExpiry.invitation.unit"
                label="Unit"
                options={[
                  { label: "Minutes", value: "m" },
                  { label: "Hours", value: "h" },
                  { label: "Days", value: "d" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>Signature:</Typography>
            </Grid>
            <Grid item xs={4}>
              <LbPatternField name="modules.iam.vendors.linkExpiry.signature.duration" label="Duration" pattern="000" />
            </Grid>
            <Grid item xs={4}>
              <LbSelect
                name="modules.iam.vendors.linkExpiry.signature.unit"
                label="Unit"
                options={[
                  { label: "Minutes", value: "m" },
                  { label: "Hours", value: "h" },
                  { label: "Days", value: "d" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container spacing={5}>
        <Grid item xs={3}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <PiHandshake fontSize={22} />
            Onboarding Mode
          </LbTitle>

          <Stack spacing={3}>
            <LbSwitch name="modules.iam.vendors.onboarding.invite" label="Invite" />
            <LbSwitch name="modules.iam.vendors.onboarding.bulk" label="Bulk" />
            <LbSwitch name="modules.iam.vendors.onboarding.fetchDetailsUsingGstin" label="Fetch details using GSTIN" />
          </Stack>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={4}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <LbLinkIcon />
            Link Expiration
          </LbTitle>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Invitation:</Typography>
            </Grid>
            <Grid item xs={4}>
              <LbPatternField name="modules.iam.vendors.linkExpiry.invitation.duration" label="Duration" pattern="000" />
            </Grid>
            <Grid item xs={4}>
              <LbSelect
                name="modules.iam.vendors.linkExpiry.invitation.unit"
                label="Unit"
                options={[
                  { label: "Minutes", value: "m" },
                  { label: "Hours", value: "h" },
                  { label: "Days", value: "d" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>Signature:</Typography>
            </Grid>
            <Grid item xs={4}>
              <LbPatternField name="modules.iam.vendors.linkExpiry.signature.duration" label="Duration" pattern="000" />
            </Grid>
            <Grid item xs={4}>
              <LbSelect
                name="modules.iam.vendors.linkExpiry.signature.unit"
                label="Unit"
                options={[
                  { label: "Minutes", value: "m" },
                  { label: "Hours", value: "h" },
                  { label: "Days", value: "d" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={4}>
          <LbTitle
            typoProps={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              columnGap: 0.5,
            }}
            stackProps={{ mb: 3 }}
          >
            <LbLinkIcon />
            GSTIN Api
          </LbTitle>
          <Stack spacing={3}>
            <LbSwitch name="modules.iam.vendors.periodicallyValidateGstStatus" label="Periodically validate for GST status" />
          </Stack>
        </Grid>
      </Grid> */}

      <Divider sx={{ my: 3 }} />

      <InputFields {...{ formik }} />
    </div>
  );
}

export default vendor;
