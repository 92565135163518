import { postRequest, orgEndpoints } from "@lb/frontend";
import { organizationsClientUrls } from "@lb/utils";
import { generatePath, useNavigate } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();

    const submitHandler = (values, { setSubmitting }) => {
      postRequest({
        url: orgEndpoints.create,
        data: values,
        baseUrl: "master",
      })
        .then((res) => {
          navigate(
            generatePath(organizationsClientUrls.edit.base, {
              organizationId: res.data._id,
            })
          );
        })
        .finally(() => setSubmitting(false));
    };

    return <Component {...props} {...{ submitHandler }} />;
  };

export default engine;
