import { LbStatusChip } from "@lb/frontend";
import { ORG_STRUCTURE } from "@lb/utils";
import { Stack, Typography } from "@mui/material";
import { map, omit, startCase, toPairs } from "lodash";
import React, { Children, Fragment } from "react";
import engine from "./engine";

function Variables({ formik }) {
  return (
    <Fragment>





      <Typography mb={2}>Predefined Organization Variables:</Typography>
      <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
        {Children.toArray(
          map(
            toPairs({
              ...omit(ORG_STRUCTURE.METADATA, [
                "adminEmail",
                "adminName",
                "administrativeDepartmentName",
                "administrativeDepartmentDescription",
              ]),
              ...omit(ORG_STRUCTURE.STATUTORY_DETAILS, ["governmentId"]),
              ...ORG_STRUCTURE.STATUTORY_DETAILS.governmentId,
            }),
            ([key, value]) => {
              return <LbStatusChip status={startCase(key)}></LbStatusChip>;
            }
          )
        )}
      </Stack>
    </Fragment>
  );
}

export default engine(Variables);
