import {
  documentsEndpoints,
  getRequest,
  putRequest,
  useReactQuery,
} from "@lb/frontend";
import { useFormik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { controller } = props;
    const { organizationId } = useParams();

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: [`documents-tags-${controller?._id}`],
      apiCall: () =>
        getRequest({
          url: documentsEndpoints.registry.getTags(
            controller?._id,
            organizationId
          ),
          baseUrl: "drm",
        }),
      options: {
        enabled: Boolean(controller?._id),
        cacheTime: 0,
      },
    });

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        tags: data?.tags || [],
      },
      validationSchema: Yup.object().shape({
        tags: Yup.array().of(
          Yup.object().shape({
            tag: Yup.object().typeError("Required").required("Required"),
            values: Yup.array().min(1, "Required").required("Required"),
          })
        ),
      }),
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        try {
          await putRequest({
            url: documentsEndpoints.registry.update(
              controller?._id,
              organizationId
            ),
            data: values,
            baseUrl: "drm",
          });
          if (controller?._id) await refetch();
          else resetForm();
        } catch (error) {
        } finally {
          setSubmitting(false);
        }
      },
    });

    console.log(formik.errors);
    const handleClose = () => {
      formik.resetForm();
      controller?.set(null);
    };

    return (
      <Component
        {...props}
        {...{ handleClose, organizationId, formik, data, isLoading }}
      />
    );
  };

export default engine;
