import * as Yup from "yup";
import { get } from "lodash";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { STORAGE_TYPES, TRANSCRIPT_STORAGE_PROVIDERS, MAX_UPLOAD_FILE_SIZE, ORGANIZATION_STATUS_OPTIONS } from "@lb/utils";
const mongodbConnectionStringRegex = /^mongodb(?:\+srv)?:\/\/(?:\w+:\w+@)?[\w\.-]+(?::\d+)?(?:\?[\w=&.-]*)?$/;

const engine =
  (Component) =>
  ({ ...props }) => {
    const { data, submitHandler } = props;

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        storage: {
          data: {
            type: get(data, "storage.data.type", STORAGE_TYPES.key.SHARED),
            credentials: {
              connectionString: get(data, "storage.data.credentials.connectionString", ""),
            },
          },
          transcripts: {
            type: get(data, "storage.transcripts.type", STORAGE_TYPES.key.SHARED),
            maxUploadSize: get(data, "storage.transcripts.maxUploadSize", MAX_UPLOAD_FILE_SIZE),
            provider: get(data, "storage.transcripts.provider", TRANSCRIPT_STORAGE_PROVIDERS.key.AZURE_BLOB),
            credentials: {
              connectionString: get(data, "storage.transcripts.credentials.connectionString", null),
              accessKeyId: get(data, "storage.transcripts.credentials.accessKeyId", null),
              secretAccessKey: get(data, "storage.transcripts.credentials.secretAccessKey", null),
              region: get(data, "storage.transcripts.credentials.region", null),
            },
          },
        },
      },
      validationSchema: Yup.object().shape({
        storage: Yup.object({
          data: Yup.object({
            type: Yup.string().oneOf(Object.values(STORAGE_TYPES.keys)).required("Data storage type is required"),
            credentials: Yup.object({
              connectionString: Yup.string().when("$storage.data.type", {
                is: (val) => {
                  return val === STORAGE_TYPES.key.EXCLUSIVE;
                },
                then: (s) => {
                  return s
                    .matches(mongodbConnectionStringRegex, "Invalid MongoDB connection string format")
                    .required("Connection string is required for exclusive storage");
                },
                otherwise: (s) => s.nullable(),
              }),
            }),
          }),
        }),
      }),
      onSubmit: submitHandler,
    });

    const isFormDisabled = useMemo(() => data.status !== ORGANIZATION_STATUS_OPTIONS.key.MAINTENANCE, [data.status]);

    return <Component {...props} {...{ formik, isFormDisabled }} />;
  };

export default engine;
