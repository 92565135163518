import {
  LbActionFooter,
  LbButton,
  LbLoading,
  LbTextField,
  LbTitle,
} from "@lb/frontend";
import { organizationsClientUrls } from "@lb/utils";
import { Close } from "@mui/icons-material";
import {
  alpha,
  AppBar,
  Box,
  Drawer,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import engine from "./engine";

function Nomenclatures({ onSubmit, isLoading, handleClose, nomenclatures }) {
  const theme = useTheme();

  return (
    <Drawer
      open
      anchor="right"
      onClose={handleClose}
      PaperProps={{ sx: { width: "40%" } }}
    >
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
      >
        <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
          <LbTitle
            backAction={handleClose}
            icon={<Close color="primary" />}
            iconRight
            typoProps={{ color: "primary.main" }}
            tooltip="Back to userss list"
          >
            Nomenclatures
          </LbTitle>
        </Toolbar>
      </AppBar>

      {isLoading ? (
        <LbLoading height="60vh" />
      ) : (
        <Formik
          enableReinitialize
          initialValues={nomenclatures || {}}
          validationSchema={Yup.object({
            department: Yup.object({
              singular: Yup.string().required("Required"),
              plural: Yup.string().required("Required"),
            }),
          })}
          {...{ onSubmit }}
        >
          {({ values, isSubmitting, handleSubmit, resetForm, dirty }) => {
            return (
              <Stack height="100%" component={"form"} onSubmit={handleSubmit}>
                <Box px={2} pt={2}>
                  <LbTitle>Department:</LbTitle>
                  <Stack spacing={2} pt={2} direction={"row"}>
                    <LbTextField
                      label="Singular"
                      placeholder="Singular for department"
                      name="department.singular"
                    />
                    <LbTextField
                      label="Plural"
                      placeholder="Plural for department"
                      name="department.plural"
                    />
                  </Stack>
                </Box>
                <LbActionFooter
                  sx={{
                    mt: "auto!important",
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <LbButton
                    type="button"
                    disabled={isSubmitting}
                    component={Link}
                    to={organizationsClientUrls.base}
                    color="warning"
                  >
                    Cancel
                  </LbButton>

                  <Stack direction="row" justifyContent={"end"} spacing={3}>
                    <LbButton
                      color="error"
                      type="button"
                      disabled={isSubmitting}
                      onClick={resetForm}
                      hidden={true}
                    >
                      Reset
                    </LbButton>
                    <LbButton
                      type="submit"
                      loading={isSubmitting}
                      disabled={!dirty}
                    >
                      Update
                    </LbButton>
                  </Stack>
                </LbActionFooter>
              </Stack>
            );
          }}
        </Formik>
      )}
    </Drawer>
  );
}

export default engine(Nomenclatures);
