import {
  LbAlert,
  deleteRequest,
  documentsEndpoints,
  getRequest,
  useReactQuery,
  useTranscriptDialog,
} from "@lb/frontend";
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

const engine =
  (Component) =>
  ({ ...props }) => {
    const [uploadController, setUploadController] = useState({
      open: false,
      _id: null,
    });
    const [manageTags, setManageTags] = useState(null);
    const { organizationId } = useParams();
    const [searchVal, setSearchVal] = useState();
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });

    const { setTranscriptDialog } = useTranscriptDialog();

    const {
      data: { data: reposData, total } = {},
      isLoading,
      refetch,
      isFetching,
    } = useReactQuery({
      queryKeys: [
        "documents",
        pagination.page,
        pagination.limit,
        organizationId,
      ],
      apiCall: () =>
        getRequest({
          url: documentsEndpoints.registry.getAll(organizationId),
          params: {
            pageNo: pagination.page,
            limit: pagination.limit,
            search: searchVal,
          },
          baseUrl: "drm",
        }),
    });

    const remove = (_id) => {
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: "Are you sure you want to delete this document?",
        danger: true,
        icon: Delete,
      }).then(async (willDelete) => {
        if (willDelete) {
          deleteRequest({
            url: documentsEndpoints.registry.remove(_id, organizationId),
            baseUrl: "drm",
          })
            .then(() => refetch())
            .finally(() => swal.close());
        }
      });
    };

    return (
      <Component
        {...props}
        {...{
          uploadController,
          setUploadController,
          setTranscriptDialog,
          pagination: {
            ...pagination,
            total,
          },
          setPagination,
          isLoading,
          reposData,
          remove,
          manageTags,
          setManageTags,
          setSearchVal,
          refetch,
          isFetching,
        }}
      />
    );
  };

export default engine;
