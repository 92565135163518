import { LbSearch, LbTitle, usersEndpoints } from "@lb/frontend";
import { AdminPanelSettings } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";

export default function Basic({ data }) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <LbTitle
          typoProps={{
            display: "flex",
            alignItems: "center",
            color: "text.secondary",
            columnGap: 0.5,
          }}
          stackProps={{ mb: 3 }}
        >
          <AdminPanelSettings />
          Admin Settings
        </LbTitle>
        <LbSearch
          label="Admin"
          name="modules.clm.admin" 
          optionLabel="email"
          placeholder="Search users"
          queryParamName="search"
          requestUrl={usersEndpoints.search(data._id)}
          idKey="_id"
          baseUrl={"iam"}
        />
      </Grid>
    </Grid>
  );
}
