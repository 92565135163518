import {
  LbButton,
  LbDataTable,
  LbMoreMenu,
  getFileTypeIcon,
  lbDate,
} from "@lb/frontend";
import { Delete, ReplayRounded, StyleOutlined } from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Link,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { map } from "lodash";
import React, { Fragment } from "react";
import { FiEdit3 } from "react-icons/fi";
import UploadEdit from "./UploadEdit";
import engine from "./engine";
import ManageTags from "./ManageTags";

function Documents({
  remove,
  isLoading,
  reposData,
  pagination,
  setPagination,
  uploadController,
  setTranscriptDialog,
  setUploadController,
  manageTags,
  setManageTags,
  setSearchVal,
  refetch,
  isFetching,
}) {
  return (
    <Fragment>
      <LbDataTable
        {...{ isLoading, pagination, setPagination }}
        height={"100%"}
        title={"Documents"}
        data={map(
          reposData,
          ({
            eventDate,
            type,
            description,
            _id,
            name,
            url,
            mimetype,
            createdAt,
          }) => [
            name,
            type,
            eventDate,
            createdAt,
            description,
            { url, mimetype, type },
            { name, _id },
          ]
        )}
        columns={[
          "Name",
          {
            name: "transcriptType",
            label: "Type",
            options: {
              customBodyRender: (value) => (
                <Avatar
                  src={getFileTypeIcon(value)}
                  variant="square"
                  sx={{ mx: "auto" }}
                />
              ),
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "eventDate",
            label: "Event Date",
            options: {
              customBodyRender: (value) => (
                <ListItemText
                  primary={lbDate(value)}
                  primaryTypographyProps={{ variant: "body2", noWrap: true }}
                />
              ),
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "uploadDate",
            label: "Upload Date",
            options: {
              customBodyRender: (value) => (
                <ListItemText
                  primary={lbDate(value)}
                  primaryTypographyProps={{ variant: "body2", noWrap: true }}
                />
              ),
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "description",
            label: "Description",
            // options: {
            //   customBodyRender: (value) => (
            //     <Typography width="100px" noWrap>
            //       {value}
            //     </Typography>
            //   ),
            // },
          },
          {
            name: "transcript",
            label: "Transcript",
            options: {
              customBodyRender: (value) => {
                return (
                  <Link
                    sx={{ cursor: "pointer" }}
                    underline="hover"
                    onClick={() => setTranscriptDialog(value)}
                  >
                    View
                  </Link>
                );
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (value) => (
                <LbMoreMenu
                  menus={[
                    {
                      label: "Edit",
                      icon: FiEdit3,
                      onClick: () =>
                        setUploadController({ open: true, _id: value._id }),
                    },
                    {
                      label: "Manage Tags",
                      icon: StyleOutlined,
                      onClick: () => setManageTags(value),
                    },
                    {
                      label: "Delete",
                      icon: Delete,
                      labelProps: { color: "error" },
                      iconProps: { color: "error" },
                      onClick: () => remove(value._id),
                    },
                  ]}
                />
              ),
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          filter: false,
          searchPlaceholder: "Search Organizations",
          customToolbar: ({ displayData }) => {
            return (
              <Fragment>
                {isFetching ? (
                  <IconButton onClick={refetch}>
                    <CircularProgress size={18} thickness={5} />
                  </IconButton>
                ) : (
                  <Tooltip title="Reload">
                    <IconButton onClick={refetch}>
                      <ReplayRounded
                        sx={{ "&:hover": { color: "primary.main" } }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <LbButton
                  sx={{ order: -1 }}
                  onClick={() => setUploadController({ open: true })}
                >
                  Upload
                </LbButton>
              </Fragment>
            );
          },
        }}
      />
      <UploadEdit
        controller={{ ...uploadController, set: setUploadController }}
      />
      <ManageTags controller={{ ...manageTags, set: setManageTags }} />
    </Fragment>
  );
}

export default engine(Documents);
