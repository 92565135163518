import {
  clauseEndpoints,
  getRequest,
  postRequest,
  useReactQuery,
  putRequest,
} from "@lb/frontend";
import { masterClientUrls } from "@lb/utils";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { groupId, orgId } = useParams();

    const handleClose = () => {
      navigate(masterClientUrls.clm.clauses.groups.base);
    };

    const { data: { data: groupData = {} } = {}, isLoading } = useReactQuery({
      queryKeys: ["clause.group", groupId, orgId],
      apiCall: () =>
      getRequest({
        url: clauseEndpoints.group.getById(groupId, orgId),
          baseUrl: "clm",
        }),
      options: {
        enabled: Boolean(groupId),
        cacheTime: 0,
      },
    });

    const handleSubmit = (values, { setSubmitting }) => {
      (groupId ? putRequest : postRequest)({
        url: groupId
          ? clauseEndpoints.group.update(groupId, orgId)
          : clauseEndpoints.group.create(),
        data: values,
        baseUrl: "clm",
      })
        .then(() => {
          queryClient.invalidateQueries(["clauses.groups"]);
          handleClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    };

    return (
      <Component
        {...props}
        {...{
          handleSubmit,
          handleClose,
          groupId,
          orgId,
          isLoading,
          groupData,
        }}
      />
    );
  };

export default engine;
