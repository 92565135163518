import { LbActionFooter, LbButton } from "@lb/frontend";
import Editor from "@monaco-editor/react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormHelperText,
  Paper,
  Stack,
  Switch,
  Toolbar,
} from "@mui/material";
import { FormikProvider } from "formik";
import { map } from "lodash";
import React, { Children, Fragment } from "react";
import engine from "./engine";
import { NestedCheckboxLabel } from "./styled";

function Notifications({ formik, nextStep, previousStep }) {
  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Stack spacing={2}>
          <Card>
            <CardHeader
              avatar={<Avatar>R</Avatar>}
              action={
                <Switch
                  name="notifications.webpush.active"
                  checked={formik?.values?.notifications?.webpush?.active}
                  onChange={formik?.handleChange}
                />
              }
              title="Web Push Notifications"
              titleTypographyProps={{
                fontWeight: "bolder",
                variant: "body1",
              }}
              subheader=""
            />
          </Card>

          <Card>
            <CardHeader
              avatar={<Avatar>R</Avatar>}
              action={
                <Switch
                  name="notifications.emails.active"
                  checked={formik?.values?.notifications?.emails?.active}
                  onChange={formik?.handleChange}
                />
              }
              title="Email Notifications"
              titleTypographyProps={{
                fontWeight: "bolder",
                variant: "body1",
              }}
              subheader=""
            />
            <Divider />
            <Collapse
              in={formik?.values?.notifications?.emails?.active}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Stack pl={1}>
                  <FormControlLabel
                    label="Shared"
                    control={
                      <Checkbox
                        checked={
                          formik?.values?.notifications?.emails?.type ===
                          "shared"
                        }
                        onChange={(event) =>
                          formik?.setFieldValue(
                            "notifications.emails.type",
                            "shared"
                          )
                        }
                      />
                    }
                  />
                  <Collapse
                    in={
                      formik?.values?.notifications?.emails?.type === "shared"
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <Stack
                      pl={2.5}
                      ml={1.1}
                      sx={{ borderLeft: "2px dashed #ccc" }}
                    >
                      <NestedCheckboxLabel
                        label="API"
                        control={
                          <Checkbox
                            sx={{ zIndex: 99 }}
                            checked={
                              formik?.values?.notifications?.emails?.service ===
                              "api"
                            }
                            onChange={(event) =>
                              formik?.setFieldValue(
                                "notifications.emails.service",
                                "api"
                              )
                            }
                          />
                        }
                      />

                      <NestedCheckboxLabel
                        label="SMTP"
                        control={
                          <Checkbox
                            checked={
                              formik?.values?.notifications?.emails?.service ===
                              "smtp"
                            }
                            onChange={(event) =>
                              formik?.setFieldValue(
                                "notifications.emails.service",
                                "smtp"
                              )
                            }
                          />
                        }
                      />
                    </Stack>
                  </Collapse>
                  <FormControlLabel
                    label="Exclusive"
                    control={
                      <Checkbox
                        checked={
                          formik?.values?.notifications?.emails?.type ===
                          "exclusive"
                        }
                        onChange={(event) =>
                          formik?.setFieldValue(
                            "notifications.emails.type",
                            "exclusive"
                          )
                        }
                      />
                    }
                  />{" "}
                  <Collapse
                    in={
                      formik?.values?.notifications?.emails?.type ===
                      "exclusive"
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <Stack
                      pl={2.5}
                      ml={1.1}
                      sx={{ borderLeft: "2px dashed #ccc" }}
                    >
                      <NestedCheckboxLabel
                        label="API"
                        control={
                          <Checkbox
                            sx={{ zIndex: 99 }}
                            checked={
                              formik?.values?.notifications?.emails?.service ===
                              "api"
                            }
                            onChange={(event) =>
                              formik?.setFieldValue(
                                "notifications.emails.service",
                                "api"
                              )
                            }
                          />
                        }
                      />
                      <Collapse
                        in={
                          formik?.values?.notifications?.emails?.service ===
                          "api"
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <Stack
                          pl={2.5}
                          ml={1.1}
                          sx={{ borderLeft: "2px dashed #ccc" }}
                        >
                          <Box
                            component={Paper}
                            elevation={7}
                            width="100%"
                            borderRadius={"8px"}
                            overflow="hidden"
                            mb={1}
                          >
                            <Editor
                              theme="vs-dark"
                              height={"200px"}
                              defaultLanguage="json"
                              value={JSON.stringify(
                                formik?.values?.notifications?.emails?.api,
                                "",
                                4
                              )}
                              loading={false}
                              options={{
                                minimap: {
                                  enabled: false,
                                },
                              }}
                              onChange={(value) => {
                                try {
                                  formik.setFieldValue(
                                    "notifications.emails.api",
                                    JSON.parse(value)
                                  );
                                } catch (error) {
                                  console.log(
                                    "🚀 ~ Notifications ~ error:",
                                    error
                                  );
                                }
                              }}
                              onValidate={(errors) => {
                                console.log(
                                  "🚀 ~ Notifications ~ a:",
                                  map(errors, "message")
                                );
                                formik.setFieldError(
                                  "notifications.emails.api",
                                  map(errors, "message")
                                );
                                return errors;
                              }}
                            />
                          </Box>
                          <FormHelperText error>
                            {Children.toArray(
                              map(
                                formik?.errors?.notifications?.emails?.api,
                                (item) => {
                                  return <div>&#9432; &nbsp; {item}</div>;
                                }
                              )
                            )}
                          </FormHelperText>
                        </Stack>
                      </Collapse>

                      <NestedCheckboxLabel
                        label="SMTP"
                        control={
                          <Checkbox
                            checked={
                              formik?.values?.notifications?.emails?.service ===
                              "smtp"
                            }
                            onChange={(event) =>
                              formik?.setFieldValue(
                                "notifications.emails.service",
                                "smtp"
                              )
                            }
                          />
                        }
                      />

                      <Collapse
                        in={
                          formik?.values?.notifications?.emails?.service ===
                          "smtp"
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <Stack
                          pl={2.5}
                          ml={1.1}
                          sx={{ borderLeft: "2px dashed #ccc" }}
                        >
                          <Box
                            component={Paper}
                            elevation={7}
                            width="100%"
                            borderRadius={"8px"}
                            overflow="hidden"
                            mb={1}
                          >
                            <Editor
                              theme="vs-dark"
                              height={"200px"}
                              defaultLanguage="json"
                              value={JSON.stringify(
                                formik?.values?.notifications?.emails?.smtp,
                                "",
                                4
                              )}
                              loading={false}
                              options={{
                                minimap: {
                                  enabled: false,
                                },
                                autoIndent: true,
                                formatOnPaste: true,
                                formatOnType: true,
                              }}
                              onChange={(value) => {
                                try {
                                  formik.setFieldValue(
                                    "notifications.emails.smtp",
                                    JSON.parse(value)
                                  );
                                } catch (error) {
                                  console.log(
                                    "🚀 ~ Notifications ~ error:",
                                    error
                                  );
                                }
                              }}
                              onValidate={(errors) => {
                                console.log(
                                  "🚀 ~ Notifications ~ a:",
                                  map(errors, "message")
                                );
                                formik.setFieldError(
                                  "notifications.emails.smtp",
                                  map(errors, "message")
                                );
                                return errors;
                              }}
                            />
                          </Box>
                          <FormHelperText error>
                            {Children.toArray(
                              map(
                                formik?.errors?.notifications?.emails?.smtp,
                                (item) => {
                                  return <div>&#9432; &nbsp; {item}</div>;
                                }
                              )
                            )}
                          </FormHelperText>
                        </Stack>
                      </Collapse>
                    </Stack>
                  </Collapse>
                </Stack>
              </CardContent>
            </Collapse>
          </Card>
        </Stack>
      </FormikProvider>

      <Toolbar />
      <LbActionFooter>
        <LbButton
          type={"button"}
          loading={formik?.isSubmitting}
          onClick={() =>
            formik?.dirty || !formik?.isValid
              ? formik?.handleSubmit?.()
              : nextStep?.()
          }
        >
          {formik?.dirty || !formik?.isValid ? "Update" : "Next"}
        </LbButton>
      </LbActionFooter>
    </Fragment>
  );
}

export default engine(Notifications);
