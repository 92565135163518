import {
  LbAlert,
  deleteRequest,
  documentsEndpoints,
  getRequest,
  useReactQuery,
} from "@lb/frontend";
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { organizationId } = useParams();
    const [searchVal, setSearchVal] = useState();
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [addEditDialogController, setAddEditDialogController] = useState({
      _id: null,
      open: false,
    });

    const {
      data: { data, total } = {},
      isLoading,
      refetch,
      isFetching,
    } = useReactQuery({
      queryKeys: ["tags", pagination.page, pagination.limit, organizationId],
      apiCall: () =>
        getRequest({
          url: documentsEndpoints.tags.getAll(organizationId),
          params: {
            pageNo: pagination.page,
            limit: pagination.limit,
            search: searchVal,
          },
          baseUrl: "drm",
        }),
    });

    const remove = (_id) => {
      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: "Are you sure you want to delete this tag?",
        danger: true,
        icon: Delete,
      }).then(async (willDelete) => {
        if (willDelete) {
          deleteRequest({
            url: documentsEndpoints.tags.remove(_id, organizationId),
            baseUrl: "drm",
          })
            .then(() => refetch())
            .finally(() => swal.close());
        }
      });
    };

    return (
      <Component
        {...props}
        {...{
          data,
          isLoading,
          pagination: {
            ...pagination,
            total,
          },
          setPagination,
          setSearchVal,
          remove,
          addEditDialogController,
          setAddEditDialogController,
          refetch,
          isFetching,
        }}
      />
    );
  };

export default engine;
